import { Button, Modal } from "@scuf/common";

export const NameLengthExceededPopup = (props: any) => {
  const { nameLength, setNameLength, nameLengthEvent } = props;
  console.log("nameLength", nameLength);
  return (
    <Modal
      className="modal-content-dataManagement-wrapper"
      open={nameLength}
      onClose={() => setNameLength(false)}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <span className="modal-text-dataManagement">Path too long.</span>
      <Modal.Footer className="modal-footer-dataManagement">
        <Button
          type="secondary"
          size="small"
          content="Ok"
          onClick={nameLengthEvent}
          className="save-secondary-dataManagement"
        />
      </Modal.Footer>
    </Modal>
  );
};
