/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
import { SET_ACTIVE_PAGE, SET_AUTH_URL, SET_DATA, SET_USER_DATA, SET_LOADING, SET_SELECTED_VIEW, SET_WMKS_DATA, SET_PERMISSION, SET_DROP_DOWN_VALUE, GET_ORG_STATUS, SET_SELECTED_VM, REMOVE_SELECTED_VM, SET_SHUTDOWN, SET_SHUTDOWN_STATUS, SET_REBOOT_STATUS, SET_RESTORE_SNAP_SHOT, SET_CREATE_SNAP_SHOT, SET_ASSIGNED_ROLES, SET_USER_ROLES, SET_UPDATE_USER_ROLES, SET_LOADING_USER_ROLES, SET_START, SET_USER_DELETE, SET_NEW_USER_DATA, SET_SELECTED_VMWARE, SET_LOGGED_IN, SET_ASSIGNED_STATUS, SET_USER_ROLES_STATUS, SET_SITEID, BLOB_SIZE_DASHBOARD_SET_DATA, DELETE_TABLE_DATA, DOWLOAD_DASHBOARD_SELECT_ROWS_DATA, SET_DASHBOARD_UPLOAD_VALIDATION, SET_UPLOAD_CHUNK, SET_DELETE_TABLE_DATA, SET_DOWLOAD_DASHBOARD_SELECT_ROWS_DATA, SET_RENAME_FILE_DATA, SET_RENAME_FOLDER_DATA, SET_TABLE_DATA, SET_UPLOAD_FILE, SET_TOKEN_CSRF, SIGNALR_CONNECTION, SET_POWER_OFF, SET_POWER_ON, SET_RESET,SET_VMID_NOTIFICATION } from './action';
import { IReduxAction, IState } from './model';
import { initialState } from './state';
import { ROLES_REQUIRED } from '../../common/authentication/config';

export default function (
    state: IState = initialState,
    action: IReduxAction
): IState {
    switch (action.type) {
        case SET_SELECTED_VIEW: {
            return {
                ...state,
                selectedView: action.payload?.selectedView,
                vmName: action.payload?.vmName,
                plantName: action.payload?.plantName,
                activePage: action.payload?.activePage
            }
        }
        case SET_TABLE_DATA: {
            return {
                ...state,
                tableData: action.payload
            }
        }
        // New
        case BLOB_SIZE_DASHBOARD_SET_DATA:
            return {
                ...state,
                blobSizeDashboard: action.payload
            }
        case SET_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload
            }
        case SET_ACTIVE_PAGE: {
            return {
                ...state,
                activePage: action.payload
            }
        }
        case SIGNALR_CONNECTION:
            return {
                ...state,
                signalR: action.payload
            }
        case SET_DATA: {
            state.permissions?.map((per: any) => {
                action.payload?.map((org: any, index: number) => {
                    if ((org && org.siteId) === (per && per.resources)) {
                        org.permissions = per.permissions;
                        org.index = index;
                        if (org && org.vmDetails && org?.vmDetails?.length > 0) {
                            _.forEach(org?.vmDetails, (value: any) => {
                                value.checked = false;
                                value.isIntermediate = false;
                                value.isOn = value.status === 'POWERED_ON' ? true : false;
                            });
                        }
                        return org;
                    }
                });
            });
            return {
                ...state,
                orgData: action.payload,
                plantName: action.payload[0]?.name,
            }
        }
        case SET_DROP_DOWN_VALUE: {
            return {
                ...state,
                selectedPlant: action.payload
            }
        }
        case GET_ORG_STATUS: {
            return {
                ...state,
                orgDataStatus: action.payload
            }
        }
        case SET_WMKS_DATA: {
            if (action.payload?.data !== null && action.payload?.data !== undefined &&
                action.payload?.data?.host !== null && action.payload?.status !== null) {
                const lastPart = window.location.pathname?.split('/').pop();
                const res: any = lastPart?.replace(/%20/g, ' ');
                const dataVal: any = localStorage.getItem(res);
                const value: any = JSON.parse(dataVal);
                localStorage.removeItem(value?.name);
                if (localStorage.getItem(res) === null) {
                    localStorage.setItem(value?.name, JSON.stringify({ name: value?.name, id: value?.id, siteId: value?.siteId, wmksData: action.payload?.data }));
                }
            }
            return {
                ...state,
                wmksDetails: action.payload?.data ? action.payload?.data : action.payload?.response?.data,
                wmksStatus: action.payload?.status ? action.payload?.status : action.payload?.response?.status
            }
        }
        case SET_TOKEN_CSRF:
            return {
                ...state,
                csrf: action.payload?.data,
                csrfStatus: action.payload?.status
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_USER_DATA:
            return {
                ...state,
                userInfo: action.payload?.userDetails
            }
        case SET_PERMISSION:
            if (action.payload?.name !== 'AxiosError') {
                const orgsDropDownDataVal = _.map(action.payload?.data?.userPermissions, (value: any) => {
                    return {
                        value: value?.resources,
                        text: value?.resourceName === '' || value?.resourceName === null ? value?.resources : value?.resourceName
                    }
                });
                const index: any = localStorage.getItem('index') || 0;
                const value: any = orgsDropDownDataVal[index]?.value;
                let permissions = {}
                if(action.payload?.status === 200 && action.payload?.data){

                const currentStatePermissions = action.payload.data.userPermissions
                permissions = currentStatePermissions.map((i: any) => {
                    const permissionOf = orgsDropDownDataVal.filter(j => j.text === i.resourceName)
                    // const m = i.resources
                    const assignedPermissions = [...i.permissions]
                    return {resources:permissionOf[0].value,resourceName:permissionOf[0].text,permissions:assignedPermissions}
                })
                // console.log("==>125 orgsDropDownDataVal","N",n,h)
            }
                return {
                    ...state,
                    orgsDropDownData: orgsDropDownDataVal,
                    selectedPlant: value,
                    activePage: index,
                    permissions: permissions,
                    permissionStatus: action.payload.status,
                }
            } else {
                return {
                    ...state,
                    permissions: null,
                    permissionStatus: 500,
                }
            }
        case SET_SITEID: {
            return {
                ...state,
                siteId: action.payload
            }
        }
        case SET_SELECTED_VM:
            state.selectedVM.push(action.payload);
            return {
                ...state,
                selectedVM: state.selectedVM
            }
        case SET_SELECTED_VMWARE:
            return {
                ...state,
                selectedVMWare: action.payload
            }
        case REMOVE_SELECTED_VM:
            const isVMPresent = _.find(
                state.selectedVM,
                (item: any) => item === action.payload
            );
            if (action.payload) {
                if (isVMPresent) {
                    _.remove(state.selectedVM, (item: any) => {
                        return item.id === action.payload?.id;
                    });
                }
            } else {
                state.selectedVM.splice(
                    0,
                    state.selectedVM.length
                );
            }
            return {
                ...state,
                selectedVM: state.selectedVM
            }
        case SET_SHUTDOWN_STATUS:
            return {
                ...state,
                shutDownStatus: action.payload
            }
        case SET_REBOOT_STATUS:
            return {
                ...state,
                rebootStatus: action.payload
            }
        case SET_START:
            return {
                ...state,
                rebootStatus: action.payload
            }
        case SET_RESTORE_SNAP_SHOT:
            return {
                ...state,
                restoreSnapShotStatus: action.payload
            }
        case SET_CREATE_SNAP_SHOT:
            return {
                ...state,
                createSnapShotStatus: action.payload
            }
        case SET_USER_ROLES_STATUS:
            return {
                ...state,
                userRolesStatus: action.payload
            }
        case SET_ASSIGNED_STATUS:
            return {
                ...state,
                assignedRolesStatus: action.payload
            }
        case SET_USER_ROLES:
            const roles = action.payload?.data?.map((role: any) => ({
                value: role.roleId,
                text: role.name
            }));
            _.remove(roles, (n: any) => {
                return n.value === ROLES_REQUIRED.TWIN_HONEYWELL_IT[0];
            });
            const data = _.sortBy(roles, ['text'])
            return {
                ...state,
                userRoles: data,
                loadingUserRoles: data?.length > 0 ? false : true
            }
        case SET_ASSIGNED_ROLES:
            const user = action.payload?.data?.map((val: any) => ({
                email: val.email,
                name: val.name,
                roles: val.roles,
                userId: val.userId,
                checked: false
            }));
            const dataUser = _.sortBy(user, ['email']);
            return {
                ...state,
                assignedRoles: dataUser,
                loadingUserRoles: dataUser?.length > 0 ? false : true
            }
        case SET_NEW_USER_DATA: {
            return {
                ...state,
                newUserData: action.payload
            }
        }
        case SET_UPDATE_USER_ROLES: {
            return {
                ...state,
                modifyUserResponse: action.payload,
            }
        }
        case SET_LOADING_USER_ROLES:
            return {
                ...state,
                loadingUserRoles: action.payload
            }
        case SET_USER_DELETE:
            return {
                ...state,
                deleteResponse: action.payload
            }
        case SET_UPLOAD_FILE:
            return {
                ...state,
                uploadfile: action.payload
            }
        // New
        case DELETE_TABLE_DATA:
            return {
                ...state,
                deleteResponseDashboard: action.payload,
            }
        case SET_DELETE_TABLE_DATA:
            return {
                ...state,
                deleteDashboardRes: action.payload,
                // message: "File deleted successfully!",
                // error: null,
                // status: "Deleted",
                // statuscode: 200
            }
        case DOWLOAD_DASHBOARD_SELECT_ROWS_DATA:
            return {
                ...state,
                downloadDashboardSelectRow: action.payload
            }
        case SET_DOWLOAD_DASHBOARD_SELECT_ROWS_DATA:
            return {
                ...state,
                setDownloadRes: action.payload
            }
        case SET_RENAME_FILE_DATA:
            return {
                ...state,
                renameRes: action.payload
            }
        case SET_RENAME_FOLDER_DATA:
            return {
                ...state,
                renameFolderRes: action.payload
            }
        case SET_DASHBOARD_UPLOAD_VALIDATION:
            return {
                ...state,
                uploadValidationRes: action.payload
            }
        case SET_UPLOAD_CHUNK:
            return {
                ...state,
                chunkUploadRes: action.payload
            }
            case SET_POWER_OFF:
                return {
                    ...state,
                    powerOffStatus: action.payload
                }
            case SET_RESET:
                return {
                    ...state,
                    resetStatus: action.payload
                }
            case SET_POWER_ON:
                return {
                    ...state,
                    powerOnStatus: action.payload
                }
            case SET_VMID_NOTIFICATION:
                return{
                    ...state,
                    vmIdNotification: action.payload
                }
        default:
            return state
    }
}