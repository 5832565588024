import config from '../authentication/config'

// VM and DP Permission
export const GET_DATA_API = `${config.API_SERVICE}/GetOrgDetails`;
export const GET_WMKS_API = `${config.API_SERVICE}/GetWebMKSToken`;
export const GET_PERMISSION_API = `${config.API_SERVICE}/UserManagement/GetUserPermissionResources`;
export const SHUT_DOWN_API = `${config.API_SERVICE}/ShutdownVM`;
export const REBOOT_API = `${config.API_SERVICE}/RebootVM`;
export const START_API = `${config.API_SERVICE}/StartVM`;
export const CREATE_SNAPSHOT_API = `${config.API_SERVICE}/CreateSnapshotVM`;
export const RESTORE_SNAPSHOT_API = `${config.API_SERVICE}/RestoreSnapshotVM`;
export const RESET_API = `${config.API_SERVICE}/ResetVM`;
export const STOP_API = `${config.API_SERVICE}/StopVM`;
// UserManagement
export const GET_ROLES_API = `${config.API_SERVICE}/UserManagement/GetUserRoles`;
export const GET_ASSIGNEDROLES_API = `${config.API_SERVICE}/UserManagement/GetAssignedUserRoles`;
export const DELETE_USER_API = `${config.API_SERVICE}/UserManagement/DeleteUserRoleBinding`;
export const ADD_SEARCH_USER_API = `${config.API_SERVICE}/UserManagement/InsertNewUserRoleBinding`;
export const UPDATE_USER_API = `${config.API_SERVICE}/UserManagement/UpdateExistingUserRoleBinding`;

// DataManagement
export const GET_TABLE_DATA_API = `${config.API_SERVICE}/FileManagement/GetAllFiles`;
export const UPLOAD_FILE_API = `${config.API_SERVICE}/FileManagement/Upload`;
export const TABLE_DATA_DELETE_API = `${config.API_SERVICE}/FileManagement/DeleteFiles`;
export const DASHBOARD_DOWNLOAD_RECORDS_API = `${config.API_SERVICE}/FileManagement/download`;
export const DASHBOARD_BLOB_SIZE_API = `${config.API_SERVICE}/FileManagement/GetStorageConsumption`;
export const DASHBOARD_FILE_RENAME_API = `${config.API_SERVICE}/FileManagement/ReNameFiles`;
export const DASHBOARD_FOLDER_RENAME_API = `${config.API_SERVICE}/FileManagement/ReNameFolder`;
export const DASHBOARD_UPLOAD_VALIDATION_API = `${config.API_SERVICE}/FileManagement/CheckFileNameExist`;
export const UPLOAD_CHUNK_API = `${config.API_SERVICE}/FileManagement/DashboardStageChunks`;

// CSRF
export const GET_XTOKEN = `${config.API_SERVICE}/UserManagement/xtoken`;
