import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../common/store/combinedReducer";
import "./css/userManagement.scss";
import {
  Breadcrumb,
  Button,
  List,
  Checkbox,
  Modal,
  Icon,
  Loader,
  Tooltip,
} from "@scuf/common";
import {
  addNewUserRoles,
  getAssignedRoles,
  getCSRFToken,
  getDeleteUser,
  getDetails,
  getPermissions,
  getUpdateUserRoles,
  getUserRoles,
  setAssignedStatus,
  setCSRFToken,
  setDeleteUser,
  setLoading,
  setLoadingUserRoles,
  setLoggedIn,
  setNewUserData,
  setSelectedView,
  setUpdateUserRoles,
} from "../../../store/action";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../header/header";
import AuthProvider from "../../../../common/authentication/authService";
import { ROLES_REQUIRED } from "../../../../common/authentication/config";

const UserManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultSelectedRole = { text: "All", value: "All" };
  const [showModifyUser, setModifyUser] = useState(false);
  const [selectedRole, setSelectedRole] = useState(defaultSelectedRole);
  const [addUserDisable, setAddUserDisable] = useState(false);
  const [dom, setDom] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [addUser, setAddUser] = useState(false);
  const [selectAllRoles, setSelectAllRoles] = useState(false);
  const [domAddUser, setDomAddUser] = useState(false);
  const [selectedUserModify, setSelectedUserModify] = useState<any>();
  const [closeDeleteModal, setCloseDeleteModal] = useState(false);
  const [closeConfirmModal, setCloseConfirmModal] = useState(false);
  const [newSearchedUser, setNewSearchedUser] = useState<any>("");
  const [showNewUserError, setNewUserError] = useState(false);
  const [isUserInHoneywellDomain, setIsUserInHoneywellDomain] = useState(false);
  const [loading, setLoadingPage] = useState(true);

  const loadingUserRoles = useSelector((state: AppState) => {
    return state.reducer.loadingUserRoles;
  });
  const userRolesStatus = useSelector((state: AppState) => {
    return state.reducer.userRolesStatus;
  });
  const activePage = useSelector((state: AppState) => {
    return state.reducer.activePage;
  });
  const userRoles = useSelector((state: AppState) => {
    return state.reducer.userRoles;
  });
  const orgData = useSelector((state: AppState) => {
    return state.reducer.orgData;
  });
  const assignedRoles = useSelector((state: AppState) => {
    return state.reducer.assignedRoles;
  });
  const deleteResponse = useSelector((state: AppState) => {
    return state.reducer.deleteResponse;
  });
  const modifyUserResponse = useSelector((state: AppState) => {
    return state.reducer.modifyUserResponse;
  });
  const assignedRolesStatus: any = useSelector((state: AppState) => {
    return state.reducer.assignedRolesStatus;
  });
  const newUserData = useSelector((state: AppState) => {
    return state.reducer.newUserData;
  });
  const selectedPlant = useSelector((state: AppState) => {
    return state.reducer.selectedPlant;
  });
  const orgDataStatus = useSelector((state: AppState) => {
    return state.reducer.orgDataStatus;
  });
  const permissions = useSelector((state: AppState) => {
    return state.reducer.permissions;
  });
  const plantName = useSelector((state: AppState) => {
    return state.reducer.plantName;
  });
  const csrf = useSelector((state: AppState) => {
    return state.reducer.csrf;
  });
  const csrfStatus = useSelector((state: AppState) => {
    return state.reducer.csrfStatus;
  });
  const permissionStatus = useSelector((state: AppState) => {
    return state.reducer.permissionStatus;
  });
  const [filterUserValue, setFilterUserValue] = useState(assignedRoles);
  const [filteredResult, setFilteredResults] = useState<any>(assignedRoles);
  const [filterRoles, setFilterRoles] = useState(userRoles);
  const [addFilterRole, setAddRole] = useState(userRoles);
  const [userExists, setUserExist] = useState<any>();
  const [userInput, setUserInput] = useState("");
  const [validEmail, setValidEmailError] = useState(false);
  const [modifyData, setModifyData] = useState<any>("");
  const [deleteData, setDeleteData] = useState<any>("");
  const [roleSearch, setRoleSearch] = useState("");
  const [orgNameVal, setOrgName] = useState("");

  const defaultValues = () => {
    assignedRoles?.forEach((user: any) => {
      user.checked = false;
    });
    setFilterRoles(userRoles);
    setAddRole(userRoles);
    setAddUserDisable(false);
    dispatch(setDeleteUser({}));
    dispatch(setUpdateUserRoles({}));
    setSaveDisabled(true);
    setRoleSearch("");
    setIsUserInHoneywellDomain(false);
  };

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      localStorage.getItem("access_token") !== undefined &&
      permissionStatus !== 500
    ) {
      if (orgDataStatus === 0) {
        console.log("selectedplant", selectedPlant);
        fetchDetails(selectedPlant);
      } else if (orgDataStatus !== 0) {
        dispatch(setLoggedIn(false));
      }
    } else if (
      permissionStatus !== 0 &&
      (permissions === null ||
        (permissions?.length <= 0 &&
          localStorage.getItem("access_token") !== undefined) ||
        permissionStatus === 500)
    ) {
      dispatch(setLoggedIn(false));
      // console.log('permission expired');
      navigate("/loginFailed");
    }
  }, [permissions, orgDataStatus]);

  const fetchDetails = (orgs: any) => {
    console.log("org", orgs);
    dispatch(
      getDetails({ authorization: localStorage.getItem("access_token"), orgs })
    );
    dispatch(
      getUserRoles({
        authorization: localStorage.getItem("access_token"),
        siteId: selectedPlant,
      })
    );
    dispatch(
      getAssignedRoles({
        authorization: localStorage.getItem("access_token"),
        siteId: selectedPlant,
      })
    );
    dispatch(setLoggedIn(true));
    const orgNameData: any = _.find(permissions, ["resources", selectedPlant]);
    if (orgNameData && orgNameData.resourceName) {
      setOrgName(orgNameData.resourceName);
    } else {
      orgNameData && setOrgName(orgNameData.resources);
    }
  };

  useEffect(() => {
    // console.log('csrfStatus', csrfStatus, csrf)
    if (csrfStatus === 200 || csrfStatus !== 0) {
      const sub: any = AuthProvider().getUserId();
      dispatch(setLoading(false));
      dispatch(setCSRFToken({ status: 0, data: false }));
      if (sub && csrf === true && permissions?.length === 0) {
        dispatch(
          getPermissions({
            userSub: sub,
            authorization: localStorage.getItem("access_token"),
          })
        );
      } else if (csrf === false) {
        // console.log('csrf expired');
        setTimeout(() => {
          navigate("/loginFailed");
        }, 500);
      } else {
        dispatch(setLoading(false));
        // console.log('in else for csrf')
      }
    } else if (csrfStatus !== 200 && csrfStatus !== 0) {
      // console.log('csrf expired');
      setTimeout(() => {
        navigate("/loginFailed");
      }, 500);
    }
  }, [csrfStatus, csrf]);

  function handleRefresh(isRefreshed: boolean) {
    if (isRefreshed == true) AuthProvider().clearCookie("XSRF-TOKEN");
    AuthProvider()
      .getRefreshToken()
      .then((resp: any) => {
        const tokenData = resp?.access_token;
        if (tokenData && resp && isRefreshed == true) {
          dispatch(
            getCSRFToken({
              authorization: localStorage.getItem("access_token"),
            })
          );
        }
      });
  }
  useEffect(() => {
    if (
      localStorage.getItem("logged_user") === null ||
      localStorage.getItem("logged_user") === JSON.stringify(false)
    ) {
      navigate("/");
    } else {
      localStorage.setItem("logged_user", JSON.stringify(true));
      const selectedViewVal = {
        selectedView: "UserManagement",
        plantName,
        activePage,
      };
      dispatch(setSelectedView(selectedViewVal));
      if (permissions?.length === 0) {
        dispatch(setLoading(true));
        handleRefresh(true);
      }
      defaultValues();
      const orgNameData: any = _.find(permissions, [
        "resources",
        selectedPlant,
      ]);
      if (orgNameData && orgNameData.resourceName) {
        setOrgName(orgNameData.resourceName);
      } else {
        orgNameData && setOrgName(orgNameData.resources);
      }
    }
  }, []);

  useEffect(() => {
    if (orgDataStatus !== 0) {
      dispatch(setLoading(false));
    }
  }, [orgDataStatus]);

  useEffect(() => {
    if (filterUserValue.length < 1) {
      selectRole(selectedRole);
    }
    if (assignedRolesStatus !== 0 && userRolesStatus !== 0) {
      setLoadingPage(false);
    }
  }, [assignedRoles, userRolesStatus, assignedRolesStatus]);

  const navigateToDashboard = (plantNameVal: string) => {
    defaultValues();
    navigate("/dashboard");
    // const selectedView = { selectedView: 'Dashboard', plantName: plantNameVal, activePage }
    // dispatch(setSelectedView(selectedView))
  };

  const waitMessage =
    "The role change may take up to 15 minutes. Please use the Refresh button to update the view.";
  // const waitMessage = Changes in the data takes a while to reflect, please check after sometime.';

  // modal for user added
  const renderUserAdded = () => {
    const closeAddUserModal = () => {
      setCloseConfirmModal(!closeConfirmModal);
      dispatch(setNewUserData({}));
      setSelectAllRoles(false);
      setNewSearchedUser("");
      setUserInput("");
      setDomAddUser(!domAddUser);
      setUserExist("");
      setValidEmailError(false);
      setNewUserError(false);
      defaultValues();
      addFilterRole?.forEach((role: any) => (role.checked = false));
    };
    return (
      <Modal
        size="small"
        className="userAddedModal"
        closeIcon={true}
        onClose={closeAddUserModal}
        open={closeConfirmModal}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          {newUserData?.data?.isAdded === true
            ? "Added Successfully"
            : "Adding Failed"}
        </Modal.Header>
        <Modal.Content className="confirmModal">
          {newUserData?.data?.isAdded === true ? (
            <>
              <>{`${newSearchedUser} added successfully.`}</>
              <p style={{ marginTop: "5%" }}>{waitMessage}</p>
            </>
          ) : (
            `${newSearchedUser} adding failed.`
          )}
        </Modal.Content>
      </Modal>
    );
  };

  // add new user modal and functions
  const addNewUser = () => {
    setAddUser(true);
    setRoleSearch("");
  };

  useEffect(() => {
    if (newUserData?.status === 200) {
      setCloseConfirmModal(!closeConfirmModal);
      dispatch(setLoadingUserRoles(false));
    } else if (
      newUserData &&
      newUserData.response &&
      (newUserData?.response?.status !== 0 ||
        newUserData?.response?.status !== 200)
    ) {
      setCloseConfirmModal(!closeConfirmModal);
      dispatch(setLoadingUserRoles(false));
    }
  }, [newUserData]);

  useEffect(() => {
    if (
      addFilterRole.length > 0 &&
      _.some(addFilterRole, ["checked", false]) === true
    ) {
      setSelectAllRoles(false);
    }
    if (
      addFilterRole.length > 0 &&
      _.every(addFilterRole, ["checked", true]) === true
    ) {
      setSelectAllRoles(true);
    }
  }, [addFilterRole, domAddUser]);

  const renderAddUser = () => {
    const isValidEmail = (emailId: string) => {
      const pattern = new RegExp(
        /(?=^[A-Za-z0-9.]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,6}$)(?=^.{1,50}$)/
      );
      return pattern.test(emailId);
    };
    const handleAddCheckbox = (checked: boolean, data: any) => {
      addFilterRole?.forEach((role: any) => {
        if (role.value === data.value) {
          if (checked) {
            role.checked = true;
            data.checked = true;
          } else {
            role.checked = false;
            data.checked = false;
          }
        }
      });
      setDomAddUser(!domAddUser);
    };
    const closeAddUserModal = () => {
      setSelectAllRoles(false);
      setAddUser(false);
      setNewSearchedUser("");
      setUserInput("");
      setDomAddUser(!domAddUser);
      setUserExist("");
      setValidEmailError(false);
      setNewUserError(false);
      defaultValues();
      addFilterRole?.forEach((role: any) => (role.checked = false));
    };
    const handleSelectRole = (val: boolean) => {
      setSelectAllRoles(val);
      addFilterRole?.forEach((userRole: any) => {
        if (
          newSearchedUser !== "" &&
          _.includes(newSearchedUser, "@honeywell.com")
        ) {
          if (
            userRole.value ===
              ROLES_REQUIRED.TWIN_HONEYWELL_MAINTENENCE_ENGINEER[0] ||
            userRole.value ===
              ROLES_REQUIRED.TWIN_HONEYWELL_COMMISSIONING_ENGINEER[0]
          ) {
            userRole.checked = val;
          } else {
            userRole.checked = false;
          }
        } else {
          userRole.checked = val;
        }
      });
    };

    const addBySearch = (event: any) => {
      const query = event.target.value;
      setUserInput(query);
    };
    const addNewUserEmail = () => {
      const userExist = _.find(assignedRoles, ["email", userInput]);
      if (newSearchedUser?.toLowerCase() === userInput.toLowerCase()) {
        // new user added and same as user input
        setNewUserError(true);
        setUserInput("");
        setUserExist("");
        setValidEmailError(false);
      } else if (userExist) {
        setUserExist(userExist);
        addFilterRole?.forEach((role: any) => (role.checked = false));
        setValidEmailError(false);
        setNewSearchedUser("");
        setUserInput("");
        setNewUserError(false);
      } else if (isValidEmail(userInput)) {
        setSelectAllRoles(false);
        addFilterRole?.forEach((role: any) => (role.checked = false));
        if (_.includes(userInput, "@honeywell.com")) {
          setIsUserInHoneywellDomain(true);
        } else {
          setIsUserInHoneywellDomain(false);
        }
        setNewSearchedUser(userInput.toLowerCase());
        setUserInput("");
        setUserExist("");
        setValidEmailError(false);
        setNewUserError(false);
      } else {
        setUserExist("");
        setSelectAllRoles(false);
        addFilterRole?.forEach((role: any) => (role.checked = false));
        setValidEmailError(true);
        setUserExist("");
        setNewSearchedUser("");
        setNewUserError(false);
      }
    };
    const handleDisable = (role: any) => {
      let val: boolean = true;
      if (
        newSearchedUser !== "" &&
        _.includes(newSearchedUser, "@honeywell.com")
      ) {
        if (
          role.value ===
            ROLES_REQUIRED.TWIN_HONEYWELL_MAINTENENCE_ENGINEER[0] ||
          role.value === ROLES_REQUIRED.TWIN_HONEYWELL_COMMISSIONING_ENGINEER[0]
        ) {
          val = false;
        } else val = true;
      } else if (newSearchedUser === "") {
        val = true;
      } else {
        val = false;
      }
      return val;
    };
    const addNewUserRole = () => {
      const roleUser = addFilterRole
        .filter((obj: any) => obj.checked && obj.checked === true)
        .map((obj: any) => obj.value);
      const newUserDataVal = {
        emailId: newSearchedUser,
        orgId: orgData[0]?.id,
        orgName: orgData[0]?.name,
        roles: roleUser,
      };
      dispatch(
        addNewUserRoles({
          data: newUserDataVal,
          authorization: localStorage.getItem("access_token"),
          siteId: selectedPlant,
        })
      );
      setNewSearchedUser(newSearchedUser);
      dispatch(setLoadingUserRoles(true));
      setAddUser(false);
    };
    return (
      <Modal
        closeIcon={true}
        data-test="modal-add-user"
        onClose={closeAddUserModal}
        open={addUser}
        id="add-user-modal"
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <span className="modal-text-header">Add User</span>
        <Modal.Content className="modal-content-add-user">
          <div className="select-roles-user">
            <div className="search-add-wrapper">
              <input
                type="email"
                id="search-box-add-user"
                onChange={addBySearch}
                value={userInput}
                className="search-new-user-add"
                placeholder="Type or paste a new email id"
                required
              />
              <Button
                type="primary"
                size="small"
                disabled={userInput === ""}
                className={
                  userInput !== "" ? "new-user-add" : "new-user-add-disable"
                }
                iconRoot="global"
                icon="slider-controls-plus"
                content="Add"
                iconPosition="left"
                onClick={() => addNewUserEmail()}
              />
            </div>
            {showNewUserError ? (
              <span className="user-exist">New user already added</span>
            ) : userExists ? (
              <span className="user-exist">user already exist</span>
            ) : validEmail === true ? (
              <span className="user-exist">Please enter valid email</span>
            ) : (
              <span className="user-exist"></span>
            )}
            <div className="selected-user-list">
              {newSearchedUser !== "" ? (
                <span className="add-user-card">
                  {newSearchedUser}
                  <Icon
                    root="building"
                    name="close-circled"
                    size="small"
                    color="white"
                    onClick={() => {
                      setNewSearchedUser("");
                      setUserInput("");
                      setDomAddUser(!domAddUser);
                      setUserExist("");
                      setValidEmailError(false);
                      setNewUserError(false);
                      addFilterRole?.forEach(
                        (role: any) => (role.checked = false)
                      );
                    }}
                  />
                </span>
              ) : null}
            </div>
            <span className="modal-text">
              Select single or multiple Roles for each user
            </span>
            {selectAllRoles === false ? (
              <Button
                type="primary"
                size="small"
                className={
                  newSearchedUser === "" || isUserInHoneywellDomain
                    ? "select-add-user-icon-disable"
                    : "select-add-user-icon"
                }
                iconRoot="global"
                icon="check"
                content="Select All"
                disabled={newSearchedUser === "" || isUserInHoneywellDomain}
                iconPosition="left"
                onClick={() => handleSelectRole(true)}
              />
            ) : (
              <Button
                type="primary"
                size="small"
                className="deselect-add-user-icon"
                icon="slidercontrols-minus"
                content="Deselect All"
                iconPosition="left"
                onClick={() => handleSelectRole(false)}
              />
            )}
            {addFilterRole?.map((role: any) => {
              return (
                <Checkbox
                  checked={role.checked}
                  onChange={(checked) => handleAddCheckbox(checked, role)}
                  label={role.text}
                  disabled={handleDisable(role)}
                />
              );
            })}
          </div>
        </Modal.Content>
        <Modal.Footer className="modal-footer">
          <Button
            id="cancel-button"
            type="inline-secondary"
            className="cancel-secondary"
            size="small"
            content="Cancel"
            onClick={closeAddUserModal}
          />
          <Button
            data-testid="add-button"
            disabled={
              newSearchedUser === "" ||
              _.every(addFilterRole, ["checked", false]) === true
            }
            className={
              newSearchedUser !== "" &&
              _.some(addFilterRole, ["checked", true]) === true
                ? "save-secondary "
                : "save-disabled-adduser"
            }
            size="small"
            content="Add"
            onClick={() => addNewUserRole()}
          />
        </Modal.Footer>
      </Modal>
    );
  };

  // roles update modal and functions
  const modifyUser = (userVal: any) => {
    setModifyUser(true);
    dispatch(setUpdateUserRoles({}));
    filterRoles?.forEach((role: any) => {
      userVal?.roles?.forEach((user: any) => {
        if (role.value === user.roleId) {
          role.checked = true;
        }
      });
      return role;
    });
    setSelectedUserModify(userVal);
    setRoleSearch("");
  };
  useEffect(() => {
    if (modifyUserResponse && modifyUserResponse?.data) {
      dispatch(setLoadingUserRoles(false));
      setSaveDisabled(true);
      if (modifyUserResponse?.data?.isUpdated !== undefined) {
        setModifyData(modifyUserResponse?.data?.isUpdated);
        setModifyUser(!showModifyUser);
      }
    }
    if (
      modifyUserResponse === undefined ||
      (modifyUserResponse &&
        modifyUserResponse.response &&
        modifyUserResponse.response.status &&
        modifyUserResponse.response.status !== 0)
    ) {
      dispatch(setLoadingUserRoles(false));
      setSaveDisabled(true);
      setModifyData(false);
      setModifyUser(!showModifyUser);
    }
  }, [modifyUserResponse]);

  useEffect(() => {
    if (assignedRolesStatus === 200 || assignedRolesStatus !== 0) {
      setDom(!dom);
      dispatch(setLoadingUserRoles(false));
      selectRole(selectedRole);
      defaultValues();
    } else if (assignedRolesStatus === 0) {
      setDom(!dom);
      setFilterUserValue([]);
      setFilteredResults([]);
    }
  }, [assignedRolesStatus, assignedRoles]);

  const renderModifyUser = () => {
    const roles: any = _.filter(filterRoles, ["checked", true]);
    const validateModifyCheckBox = (user: any) => {
      let checked: any = false;
      filterRoles?.forEach((role: any) => {
        if (role.value === user.value) {
          checked = user.checked ? user.checked : role.checked;
        }
      });
      return checked;
    };
    const handleModifyCheckbox = (checked: boolean, data: any) => {
      filterRoles?.forEach((role: any) => {
        if (role.value === data.value) {
          if (checked) {
            role.checked = true;
            data.checked = true;
            setSaveDisabled(false);
          } else {
            role.checked = false;
            data.checked = false;
          }
        }
      });
      if (_.every(filterRoles, ["checked", false]) === true) {
        setSaveDisabled(true);
      } else {
        setSaveDisabled(false);
      }
      setDom(!dom);
    };
    const closeModal = () => {
      setModifyUser(!showModifyUser);
      setModifyData("");
      dispatch(setUpdateUserRoles({}));
      setSelectedUserModify({});
      setSaveDisabled(true);
      defaultValues();
      filterRoles?.map((role: any) => (role.checked = false));
    };
    const saveModal = () => {
      const modifyRole = filterRoles
        .filter((obj: any) => obj.checked && obj.checked === true)
        .map((obj: any) => obj.value);
      const existingRole = selectedUserModify?.roles?.map((obj: any) => {
        return {
          roleId: obj.roleId,
          roleBindingId: obj.bindingId,
        };
      });
      const modifyUserRole = {
        emailId: selectedUserModify?.email,
        name: selectedUserModify?.name,
        orgId: orgData[0]?.id,
        orgName: orgData[0]?.name,
        roles: modifyRole,
        existingRoles: existingRole,
      };
      dispatch(
        getUpdateUserRoles({
          data: modifyUserRole,
          authorization: localStorage.getItem("access_token"),
          siteId: selectedPlant,
          userId: selectedUserModify?.userId,
        })
      );
      dispatch(setLoadingUserRoles(true));
      setModifyUser(!showModifyUser);
    };
    const handleDisable = (role: any) => {
      let val: boolean = false;
      if (
        selectedUserModify?.email !== "" &&
        _.includes(selectedUserModify?.email, "@honeywell.com")
      ) {
        if (
          role.value ===
            ROLES_REQUIRED.TWIN_HONEYWELL_MAINTENENCE_ENGINEER[0] ||
          role.value === ROLES_REQUIRED.TWIN_HONEYWELL_COMMISSIONING_ENGINEER[0]
        ) {
          val = false;
        } else val = true;
      } else {
        val = false;
      }
      return val;
    };
    return (
      <Modal
        closeIcon={true}
        data-test={"modal-add-user"}
        onClose={closeModal}
        open={showModifyUser}
        id={
          modifyData === true || modifyData === false
            ? "response-modal"
            : "new-user-modal"
        }
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          <span className="modify-role-modal-header">
            {modifyData === true
              ? "Modified Successfully"
              : modifyData === false
              ? "Failed to modify"
              : "Modify Role"}
          </span>
        </Modal.Header>
        <Modal.Content className="modal-content">
          {modifyData === true ? (
            `${selectedUserModify?.email} roles modified successfully .`
          ) : modifyData === false ? (
            `${selectedUserModify?.email} role modification failed.` // username roles modified successfully / modification failed
          ) : (
            <>
              <span className="modal-text">
                Select single or multiple Roles
              </span>
              {filterRoles?.map((role: any) => {
                return (
                  <Checkbox
                    checked={validateModifyCheckBox(role)}
                    onChange={(checked) => handleModifyCheckbox(checked, role)}
                    label={role.text}
                    disabled={handleDisable(role)}
                  />
                );
              })}
            </>
          )}
          {modifyData === true ? (
            <p style={{ marginTop: "5%" }}>{waitMessage}</p>
          ) : null}
        </Modal.Content>
        {modifyData === "" ? (
          <Modal.Footer className="modal-footer">
            <Button
              id="cancel-button"
              type="inline-secondary"
              className="cancel-secondary"
              size="small"
              content="Cancel"
              onClick={closeModal}
            />
            <Button
              data-testid="add-button"
              disabled={saveDisabled === true ? true : false}
              className={
                saveDisabled ? "save-secondary-disable" : "save-secondary"
              }
              size="small"
              content="Save"
              onClick={saveModal}
            />
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  };

  // modal for delete confirmation and function
  useEffect(() => {
    if (deleteResponse && deleteResponse.data) {
      dispatch(setLoadingUserRoles(false));
      if (deleteResponse?.data?.isDeleted !== undefined) {
        setDeleteData(deleteResponse?.data?.isDeleted);
        setCloseDeleteModal(!closeDeleteModal);
      }
    }
    if (
      deleteResponse === undefined ||
      (deleteResponse &&
        deleteResponse.status &&
        deleteResponse?.status !== 0 &&
        deleteResponse?.status !== 200)
    ) {
      dispatch(setLoadingUserRoles(false));
      setDeleteData("");
    }
  }, [deleteResponse]);
  const renderDeleteConfirmation = () => {
    const deleteUsers = () => {
      const users = {
        orgId: orgData[0]?.id,
        orgName: orgData[0]?.name,
        roles: _.map(selectedUserModify.roles, (role: any) => ({
          roleId: role.roleId,
          roleBindingId: role.bindingId,
        })),
      };
      dispatch(
        getDeleteUser({
          authorization: localStorage.getItem("access_token"),
          deleteUsers: users,
          siteId: selectedPlant,
          userId: selectedUserModify.userId,
        })
      );
      setCloseDeleteModal(!closeDeleteModal);
      dispatch(setLoadingUserRoles(true));
    };
    const closeDelete = () => {
      setDeleteData("");
      dispatch(setDeleteUser({}));
      setSelectedUserModify({});
      setCloseDeleteModal(!closeDeleteModal);
      defaultValues();
    };
    return (
      <Modal
        size="small"
        className={deleteData === "" ? "userDeleteModal" : "userAddedModal"}
        closeIcon={true}
        onClose={() => closeDelete()}
        open={closeDeleteModal}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          {deleteData === false
            ? "Delete Failed"
            : deleteData === true
            ? "Deleted Successfully"
            : "Delete User"}
        </Modal.Header>
        <Modal.Content className="confirmModal">
          {deleteData === false
            ? `${selectedUserModify?.email} could not be deleted.`
            : deleteData === true
            ? `Deleted item : ${selectedUserModify?.email}.`
            : `Are you sure you want to delete ${selectedUserModify?.email}?`}
          {deleteData === true ? (
            <p style={{ marginTop: "5%" }}>{waitMessage}</p>
          ) : null}
        </Modal.Content>
        {deleteData === "" ? (
          <Modal.Footer>
            <Button
              className="cancel-secondary"
              type="secondary"
              size="small"
              content="Cancel"
              onClick={() => closeDelete()}
            />
            <Button
              className="save-secondary"
              type="primary"
              size="small"
              content="Delete"
              onClick={() => deleteUsers()}
            />
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  };

  const selectRole = (role: any) => {
    if (role && role.text !== "All") {
      const filterRole = assignedRoles?.filter((item: any) => {
        const val = _.filter(item.roles, ["roleId", role.value]);
        if (val.length > 0) {
          return item;
        }
      });
      setFilterUserValue(filterRole);
      setFilteredResults(filterRole);
      setSelectedRole(role);
    } else {
      setFilterUserValue(assignedRoles);
      setSelectedRole(role);
    }
    setRoleSearch("");
    setDom(!dom);
  };

  const handleDelete = (user: any) => {
    dispatch(setDeleteUser({}));
    setSelectedUserModify(user);
    setCloseDeleteModal(true);
    setRoleSearch("");
  };

  const filterBySearch = (event: any) => {
    const query = event.target.value;
    setRoleSearch(query);
    let updatedList =
      selectedRole && selectedRole.text === "All"
        ? [...assignedRoles]
        : [...filteredResult];
    updatedList = updatedList?.filter((item) => {
      return item?.email?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilterUserValue(updatedList);
  };

  const refreshList = () => {
    setDom(!dom);
    dispatch(
      getAssignedRoles({
        authorization: localStorage.getItem("access_token"),
        siteId: selectedPlant,
      })
    );
    dispatch(setLoadingUserRoles(true));
    setRoleSearch("");
    selectRole(selectedRole);
  };
  return (
    <>
      <HeaderComponent />
      <div className="extended-container-user">
        {loading ? (
          <Loader overlayColor="rgba(0,0,0,0.2)">
            <div className="placeholder" />
          </Loader>
        ) : null}
        {loadingUserRoles && (
          <Loader overlayColor="rgba(0,0,0,0.2)">
            <div className="placeholder" />
          </Loader>
        )}
        {renderModifyUser()}
        {renderAddUser()}
        {renderDeleteConfirmation()}
        {renderUserAdded()}
        <Breadcrumb>
          <Breadcrumb.Item
            data-test="bread-crumb-item1"
            onClick={() => navigateToDashboard(orgNameVal)}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigateToDashboard(orgNameVal)}>
            {orgNameVal}
          </Breadcrumb.Item>
          <Breadcrumb.Item>Manage User & Site</Breadcrumb.Item>
        </Breadcrumb>
        <div className="button-group-wrapper">
          <div className="button-group-1">
            <span className="role-text">
              {selectedRole && selectedRole?.text === "All"
                ? "All Users and Roles"
                : selectedRole.text}
            </span>
            <input
              id="search-box"
              onChange={filterBySearch}
              className="search-user"
              placeholder="Search by email"
              value={roleSearch}
            />
          </div>
          <div className="button-group-2">
            <Tooltip
              element={
                <Icon
                  name="refresh"
                  size="medium"
                  className="refresh-user-list"
                  onClick={() => refreshList()}
                />
              }
              content=""
              position="top right"
            />
            <Button
              type="primary"
              size="small"
              disabled={addUserDisable}
              className={
                addUserDisable === true ? "user-icon" : "user-select-icon"
              }
              iconRoot="global"
              icon="slider-controls-plus"
              content="Add User"
              iconPosition="left"
              onClick={() => addNewUser()}
            />
          </div>
        </div>
        <div className="user-filter-wrapper">
          <div className="roles-filter-wrapper">
            {userRoles?.length > 0 && (
              <div
                className={
                  selectedRole && selectedRole?.text === "All"
                    ? "selected-list-content"
                    : "list-content"
                }
                onClick={() => selectRole(defaultSelectedRole)}
              >
                All Roles
              </div>
            )}
            {userRoles?.map((role: any) => {
              return (
                <List>
                  <List.Content
                    className={
                      selectedRole &&
                      selectedRole?.text === (role && role?.text)
                        ? "selected-list-content"
                        : "list-content"
                    }
                    onClick={() => selectRole(role)}
                  >
                    {role && role?.text}
                  </List.Content>
                </List>
              );
            })}
          </div>
          <div className="users-wrapper">
            <List>
              <List.Content>
                {assignedRolesStatus &&
                assignedRolesStatus === 200 &&
                assignedRoles &&
                assignedRoles.length > 0 &&
                filterUserValue &&
                filterUserValue.length > 0
                  ? filterUserValue?.map((element: any) => {
                      return (
                        <div
                          className="user-data-wrapper"
                          key={element?.userId}
                        >
                          <div className="useMgmt-detail">
                            <span className="userMgmt-card-data-value">
                              User Email ID :{" "}
                              <span className="userMgmt-card-value">
                                {element?.email}
                              </span>
                            </span>
                            <span className="userMgmt-card-role-data">
                              Assigned Roles:
                              <div className="userMgmt-card-roles">
                                {_.sortBy(element?.roles, ["name"]).map(
                                  (role: any) => {
                                    return (
                                      <span className="userMgmt-card-role-value">
                                        {role?.name}
                                      </span>
                                    );
                                  }
                                )}
                              </div>
                            </span>
                          </div>
                          <div className="userMgmt-actions">
                            <span
                              className="modify-text"
                              onClick={() => modifyUser(element)}
                            >
                              Modify Role
                            </span>
                            <span
                              className="modify-text"
                              onClick={() => handleDelete(element)}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      );
                    })
                  : assignedRolesStatus !== 0 && (
                      <div className="useMgmt-detail-nodata">
                        <span className="userMgmt-card-value">
                          No users to display
                        </span>
                      </div>
                    )}
              </List.Content>
            </List>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserManagement;
