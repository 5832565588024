import {
  Button,
  Input,
  Modal,
  Tooltip,
  Icon,
  Notification,
  Progress,
} from "@scuf/common";
import { DataTable } from "@scuf/datatable";
import { isNull } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { customToast } from "../../../../../../App";
import { useDispatch, useSelector } from "react-redux";
import { downloadRecordFile } from "../../../../../store/action";
import { AppState } from "../../../../../../common/store/combinedReducer";
import moment from "moment";
import { upload } from "@testing-library/user-event/dist/upload";
import { DASHBOARD_DOWNLOAD_RECORDS_API } from "../../../../../../common/apiEndPoint/apiEndPoint";
import { cookieVal } from "../../../../../store/service";
import _ from "lodash";
const ReactToastify = require("react-toastify");

export const DataManagementGrid = (props: any) => {
  const {
    pathcount,
    finallist,
    paths,
    xval,
    newFileKey,
    inputEle,
    newArr,
    userEmail,
    downloadSelectedRowData,
    selectedRowData,
    // siteId,
    selectedRow,
    click,
    isNewFolderBtnDisabled,
    allowTableRowPerPage,
    showProgress,
    showReset,
    disablefuncupload,
    buttondisable,
    disablefunc,
    buttondisabledownloadcrsr,
    buttondisabledelete,
    buttondisabledownload,
    pagecount,
    currentpage,

    // Methods
    setPathcount,
    setPaths,
    setSelectedRow,
    setSelectedRowData,
    setDownloadSelectedRowData,
    setButtondisable,
    setButtondisabledownload,
    setButtondisabledownloadcrsr,
    setButtondisabledelete,
    setDisablefunc,
    setDisablefuncUpload,
    setOldFileSize,
    setOldFileType,
    // setSelectAll,
    setIsNewFolderBtnDisabled,
    setxval,
    // setEnterKeyStorage,
    setfileKey,
    setNameLength,
    // setPathRenameFileSend,
    handlechangelink,
    setemptyFldrName,
    setFexist,
    dateFormatFun,
    // setEditInput,
    setNewArr,
    setLoadingPage,
    setDeleteOpen,
    setNewFileName,
    setOpen,
    setHideInput,
    setHideInputValMsg,
    setOldFileName,
    setRenameFileExtension,
    eventBeforeUploadApi,
    refreshCurrentPageOfTable,
    handlepagination,
    // download
    progressDownload,
    retryCountDownload,
    retryBtnState,
    fileNameVal,
    chunks,
    receivedLength,
    contentLength,
    setProgressDownload,
    setDownloadProgress,
    showDownloadProgress,
    setReceivedLength,
    setChunks,
    setRetryCountDownload,
    setFileName,
    setContentLength,
    hideRetryBtnState,
    // upload
    showRetry,
    resetFileUpload,
    fileNameUpload,
    progress,
    uploadFile,
    setDom,
    dom,
    resetChunkProperties,
    // search
    setFilteredGridData,
    filteredGridData,
    setSearchBoxValue,
    setIsSearchDataEmpty,
    searchBoxValue,
    isSearchDataEmpty,
    setAllowTableRowPerPage,
  } = props;

  const siteId = useSelector((state: AppState) => {
    return state.reducer.selectedPlant;
  });

  const tdata = useSelector((state: AppState) => {
    return state.reducer?.tableData;
  });

  const handleselection = (e: any) => {
    // console.log('e', e);
    const isInProgress = _.find(e, ["status", "FILE_UPLOAD_INPROGRESS"]);
    if (isNull(e[pathcount]?.path)) {
      const pathsss = finallist.join("/");
      paths.push(pathsss);
    } else {
      paths.push(e[pathcount]?.path);
    }
    setPathcount(pathcount + 1);
    setPaths(paths);
    setSelectedRow(e);
    setSelectedRowData(e);
    setDownloadSelectedRowData(e);
    console.log("isInProgress", isInProgress, e);
    if (isInProgress !== undefined || e.length === 0) {
      setDisablefunc(true);
      setDisablefuncUpload(false);
    } else {
      setDisablefunc(false);
      setDisablefuncUpload(true);

      // Record filesize conversion
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(e[0]?.fileSize) / Math.log(k));
      setOldFileSize(parseFloat((e[0].fileSize / Math.pow(k, i)).toFixed()));
      setOldFileType(sizes[i]);
    }
  };

  // const handleselectall = (e: any) => {
  //     setSelectAll(e)
  // }

  const handleChangeFileName = (e: any, id: any) => {
    const newData = xval?.map((item: any) => {
      // TODO: this is slowing down client side execution (can be improved)
      // console.log('item', item, id, e)
      if (item?.id === id) {
        const path = e.target.value
          .toString()
          .trimStart()
          .replace(/[^\w]/g, ""); // .replace(/[^a-zA-Z0-9_\s]|(?!\s)\s+/g, '');
        // .replace(/[^\w]/g, '');// .replace(/[\\\/?:*'<>|]/gi, '');
        path
          ? setIsNewFolderBtnDisabled(false)
          : setIsNewFolderBtnDisabled(true);
        return {
          ...item,
          path,
        };
      } else {
        return {
          ...item,
        };
      }
    });

    setxval(newData);
  };

  const handleKeyDown = (e: any) => {
    // if (e.key === 'Enter' || e.key === 'Tab') {
    // setEnterKeyStorage(e.key)
    if (checkFilenFolder(e.target.value) === false) {
      setfileKey("");
      e.preventDefault();
    }
    // checkFilenFolder(e.target.value);
    // }
  };

  function rendererNameColSearch(cellData: any) {
    let currentPath = `${finallist.join("/")}`;
    let currFolder = cellData?.rowData?.path.replace(
      new RegExp(`^${currentPath}\\s*`),
      ""
    );
    let _currTopLevelFolder = currFolder.split("/").filter(Boolean)[0];
    return cellData?.rowData?.path || cellData?.rowData?.path === "" ? (
      <Tooltip
        element={
          <div className="ellip">{cellData?.rowData?.fileName?.trimEnd()}</div>
        }
        content={cellData?.rowData?.fullPath}
        header={cellData?.rowData?.fileName}
        size="small"
        position="bottom left"
      />
    ) : newFileKey !== cellData?.rowData?.id ? (
      <Tooltip
        element={
          <a onClick={() => handlechangelink(cellData?.rowData, tdata)}>
            <div className="ellip">{_currTopLevelFolder}</div>
          </a>
        }
        content={_currTopLevelFolder}
        position="bottom left"
      />
    ) : (
      <input
        ref={inputEle}
        name={"fileName"}
        value={cellData?.rowData?.path}
        onChange={(e) => handleChangeFileName(e, cellData?.rowData?.id)}
        // onKeyDown={handleKeyDown}
        onBlur={handleKeyDown}
        className="new-folder"
        autoFocus
      />
    );
  }
  function rendererNameColNoSearch(cellData: any) {
    let currentPath = `${finallist.join("/")}/`;
    let currFolder = cellData.rowData.path.replace(
      new RegExp(`^${currentPath}\\s*`),
      ""
    );
    let _currTopLevelFolder = currFolder.split("/").filter(Boolean)[0];
    return cellData.rowData.fileType === "folder" ? (
      newFileKey !== cellData.rowData.id ? (
        <Tooltip
          element={
            <a onClick={() => handlechangelink(cellData.rowData, tdata)}>
              <div className="ellip">{_currTopLevelFolder}</div>
            </a>
          }
          content={_currTopLevelFolder}
          position="bottom left"
        />
      ) : (
        <input
          ref={inputEle}
          name={"fileName"}
          value={cellData.rowData.path}
          onChange={(e) => handleChangeFileName(e, cellData.rowData.id)}
          // onKeyDown={handleKeyDown}
          onBlur={handleKeyDown}
          className="new-folder"
          autoFocus
        />
      )
    ) : (
      <Tooltip
        element={
          <div className="ellip">{cellData.rowData.fileName.trimEnd()}</div>
        }
        content={cellData.rowData.fullPath}
        header={cellData.rowData.fileName}
        size="small"
        position="bottom left"
      />
    );
  }
  const statusRendererUpdatedBy = (cellData: any) => {
    return (
      <Tooltip
        element={<div className="ellip">{cellData.rowData.updatedBy}</div>}
        content={cellData.rowData.updatedBy}
        position="bottom left"
      />
    );
  };
  const rendererPath = (cellData: any) => {
    return isSearchDataEmpty ? (
      <Tooltip
        element={<div className="ellip">{}</div>}
        content={cellData.rowData.path}
        position="bottom left"
      />
    ) : (
      <Tooltip
        element={<div className="ellip">{cellData.rowData.path}</div>}
        content={cellData.rowData.path}
        position="bottom left"
      />
    );
  };
  const rendererFileSizeCol = (cellData: any) => {
    if (!cellData.rowData.fileSize) return <></>;
    else if (
      (isSearchDataEmpty && cellData.rowData.fileType === "folder") ||
      cellData.rowData.status === "FILE_UPLOAD_INPROGRESS"
    )
      return <></>;
    else {
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(cellData.rowData.fileSize) / Math.log(k));
      let value: any = (cellData.rowData.fileSize / Math.pow(k, i)).toFixed(4);
      if (value % 1 === 0) {
        value = cellData.rowData.fileSize / Math.pow(k, i);
      } else {
        value = (cellData.rowData.fileSize / Math.pow(k, i)).toFixed(2);
      }
      return (
        <p>
          {value} {sizes[i]}
        </p>
      );
    }
  };
  const statusRendererfiletype = (cellData: any) => {
    if (!cellData.rowData.path)
      return (
        <p>
          {" "}
          <Icon name="document" size="small" />
        </p>
      );
    return (
      <p>
        <Icon root="common" name="folder-open" size="small" />
      </p>
    );
  };

  function checkFilenFolder(name: any) {
    // console.log('name', name)
    const folderPresent = newArr.find(
      (folder: any) =>
        folder?.path?.trimEnd().toLowerCase() === name.trimEnd().toLowerCase()
    );
    if (name.length === 0) {
      setemptyFldrName(true);
      return true;
    } else if (name.length > 100) {
      setNameLength(true);
      return true;
    }
    if (folderPresent) {
      setFexist(true);
      return true;
    } else {
      setFexist(false);
      return false;
    }
  }
  const handleCreateNewFolder = () => {
    let date = new Date();
    var isoDate = dateFormatFun(date);
    let randomId = uuidv4();

    const timestamp = moment().format("yy_MM_DD_HH_mm_ss_SSS");

    const newbody: any = {
      containerFolder: "New folder",
      containerName: "dpdevsitestorage",
      createdAt: "2023-09-19T07:47:02.7202602Z",
      createdBy: "NeutralNodeFolder",
      eTag: null,
      fileModifiedAt: "2023-09-15T09:54:51.3399924Z",
      fileName: null,
      fileSize: "",
      fileType: "folder",
      hashValue: "A219ADEDFACC25149BC49613E66B2D0A",
      id: randomId,
      partitionKey: "1920d035-749f-49ae-aae1-b5a4bfb572de",
      path: "My_folder", // `New folder ${timestamp}`,
      source: "Web",
      rowKey: "9a87576e-4dde-49fe-823e-89e38cbb57af",
      status: "",
      timestamp: "",
      updatedAt: isoDate,
      updatedBy: userEmail,
    };

    setfileKey(newbody?.id);
    setxval([newbody, ...xval]);
    // setEditInput(true);
    setNewArr([...xval]);
  };

  /************ Download Api Call ***************/
  // const ToastNotification = () => (
  //     <>
  //         <Notification
  //             // onCloseClick={() => console.log('closed')}
  //             severity='critical'
  //             title={`Please select file name`}
  //             className='toastnotify'
  //         />
  //     </>
  // )
  // initiate download function that returns reader object
  const initiateDownload = async (data: any) => {
    const csrfVal: any = cookieVal();
    // console.log('range receivedLength', data, data.startRange, csrfVal);
    try {
      // post request to download file
      const response: any = await fetch(`${DASHBOARD_DOWNLOAD_RECORDS_API}`, {
        method: "POST",
        headers: {
          Range: `bytes=${data.startRange}-`,
          siteId: data.siteId,
          Authorization: `Bearer ${data.authorization}`,
          Accept: "*/*",
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip, deflate, br",
          Connection: "keep-alive",
          "X-XSRF-TOKEN": csrfVal,
        },
        body: JSON.stringify(data.param),
      });

      if (!response.ok) {
        // console.log('in fetch error', response, response.body);
        if (response.status === 403) {
          resetChunkProperties();
          setDisablefunc(true);
          setRetryCountDownload(0);
          customToast(
            "Previous Download is in progress. Please try again after sometime",
            "critical"
          );
        } else if (response.status === 413) {
          resetChunkProperties();
          setDisablefunc(true);
          setRetryCountDownload(0);
          customToast(
            "Total size of files selected for download cannot exceed 2 GB",
            "critical"
          );
        } else {
          customToast(
            "Something went wrong, please try after sometime",
            "critical"
          );
          if (retryCountDownload < 5) {
            setRetryCountDownload(retryCountDownload + 1);
            hideRetryBtnState(false);
          } else {
            setDisablefunc(true);
            setRetryCountDownload(0);
            resetChunkProperties();
          }
        }
        // alert(`HTTP error! status: ${response.status}`);
        return;
      }
      const blobReader = response.body.getReader();
      let contLen = +response.headers.get("Content-Length");
      let receiveLen = 0;
      let chunk: any = [];
      if (data.startRange === 0) {
        setContentLength(contLen);
        setReceivedLength(receiveLen);
        setChunks(chunk);
      } else {
        contLen = contentLength;
        receiveLen = receivedLength;
        chunk = chunks;
      }

      while (receiveLen !== contLen) {
        const { done, value } = await blobReader.read();
        if (done) {
          // console.log('done');
          return;
        } else {
          chunk = [...chunk, value];
          receiveLen += value?.length;
          setProgressDownload(Math.round((receiveLen / contLen) * 100));
          if (receiveLen === contLen) {
            const fileName = "DP_DOWNLOAD_" + getFormattedDateTime() + ".zip";
            processDownloadedChunk(chunk, receiveLen, fileName);
            setReceivedLength(0);
            setChunks(0);
            setDisablefuncUpload(false);
            setDom(!dom);
          } else {
            setReceivedLength(receiveLen);
            setChunks(chunk);
          }
        }
      }
    } catch (error) {
      // console.log('error', error);
      // alert(error);
      if (retryCountDownload < 5) {
        customToast("File download Failed! Please Retry.", "critical");
        setRetryCountDownload(retryCountDownload + 1);
        hideRetryBtnState(false);
      } else {
        customToast("File download Failed!", "critical");
        // setDisablefunc(true);
        resetChunkProperties();
      }
    }
  };

  const getFormattedDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;

    return formattedDateTime;
  };

  const processDownloadedChunk = async (
    chunksArr: any,
    receLen: any,
    fileName: string
  ) => {
    let chunksAll = new Uint8Array(receLen);
    let position = 0;
    for (let chunk of chunksArr) {
      chunksAll.set(chunk, position);
      position += chunk?.length;
    }

    const blob = new Blob([chunksAll]);
    const urlObject = URL.createObjectURL(blob);

    // Create a link and click it to start the download
    const link = document.createElement("a");
    link.href = urlObject;
    link.download = fileName;
    setDownloadProgress(false);
    setSelectedRow([]);
    setDisablefunc(true);
    resetChunkProperties();
    customToast(`File Downloaded!`, "success");
    // console.log()
    link.click();
    // refreshCurrentPageOfTable();
    setTimeout(() => URL.revokeObjectURL(urlObject), 60 * 1000);
  };

  const realDownload = async () => {
    try {
      if (downloadSelectedRowData?.length > 0) {
        const resultArr = downloadSelectedRowData.map(
          (item: any, index: any) => {
            item.fileName ? setFileName(item.fileName) : setFileName("");
            let currentPath = `${finallist.join("/")}/`;
            let currFolder = item.path.replace(
              new RegExp(`^${currentPath}\\s*`),
              ""
            );
            let _currTopLevelFolder = currFolder.split("/").filter(Boolean)[0];
            return {
              path: item.path
                ? item.fileType === "folder" && isSearchDataEmpty
                  ? (currentPath !== "/" ? currentPath : "") +
                    _currTopLevelFolder
                  : item.path
                : "",
              fileName: item.fileName
                ? !isSearchDataEmpty
                  ? item.fileName
                  : item.fileType !== "folder"
                  ? item.fileName
                  : ""
                : "",
            };
          }
        );
        const data = {
          container: downloadSelectedRowData[0].containerName
            ? downloadSelectedRowData[0].containerName
            : "",
          // 'updatedBy': downloadSelectedRowData[0]?.updatedBy ? downloadSelectedRowData[0]?.updatedBy : '',
          downloadfilesmodel: resultArr,
        };
        // console.log('receivedLength', receivedLength);
        setDownloadProgress(true);
        setSelectedRow([]);
        setLoadingPage(false);
        hideRetryBtnState(true);
        setDisablefuncUpload(true);
        await initiateDownload({
          authorization: localStorage.getItem("access_token"),
          param: data,
          siteId: siteId,
          startRange: receivedLength,
        });
      }
    } catch (error) {
      // console.log('error', error);
      setDownloadProgress(false);
      setSelectedRow([]);
      customToast(
        "Unable to download the selected file, please try after sometime",
        "critical"
      );
    }
  };
  const handletoastdownload = async () => {
    customToast(
      "File download started. Please wait for it to complete",
      "information"
    );
    setChunks([]);
    setReceivedLength(0);
    hideRetryBtnState(true);
    setProgressDownload(0);
    setContentLength(0);
    if (downloadSelectedRowData?.length > 0 && receivedLength === 0) {
      setLoadingPage(true);
      setTimeout(() => {
        realDownload();
      }, 500);
      // await dispatch(downloadRecordFile({ authorization: localStorage.getItem('access_token'), param: data, siteid: siteId }))
    } else {
      // ReactToastify.toast(<ToastNotification />)
      customToast("Please select file name", "critical");
    }
  };
  /************End Download Api Call ***************/

  /*************Delete Api Event ******************/
  // const ToastNotificationdelete = () => (
  //     <div>
  //         <Notification
  //             // onCloseClick={() => console.log('closed')}
  //             severity='critical'
  //             title={'Please select file name'}
  //             className='toastnotify'
  //         />
  //     </div>
  // )

  const handletoastdelete = async () => {
    if (selectedRowData?.length > 0) {
      setDeleteOpen(true);
    } else {
      // ReactToastify.toast(<ToastNotificationdelete />)
      customToast("Please select file name", "critical");
    }
  };
  /*************End Delete Records Api Event ******************/

  /*************Rename Api Call Event ******************/
  const statusRendererRowFileRename = (e: any) => {
    return (
      <div className="pointer-cls" onClick={setOldFileNameEvent}>
        <Icon
          name="edit"
          className="pointer-cls"
          onClick={setOldFileNameEvent}
          size="medium"
          color="blue"
        />
      </div>
    );
  };

  const statusRender = (e: any) => {
    switch (e.value) {
      case "FILE_UPLOAD_FAILED":
        return <div>File Upload Failed</div>;
      case "FILE_UPLOAD_INPROGRESS":
        return <div>File Upload Inprogress</div>;
      default:
        return <div>{e.value}</div>;
    }
  };
  const handleSearchInputTextBox = (e: any) => {
    let newData: any;
    console.log("e", e);
    if (e !== "") {
      newData = xval.filter((item: any) => {
        if (
          item.fileName &&
          item.fileName.toLowerCase().includes(e.toLowerCase())
        ) {
          return true;
        } else if (
          item.path &&
          item.path.toLowerCase().includes(e.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredGridData(newData);
      setIsSearchDataEmpty(false);
      // console.log('table row per page: '+allowTableRowPerPage);
      // setAllowTableRowPerPage(newData.length);
      // console.log('table row per page: '+allowTableRowPerPage);
    } else {
      let currentPath = `${finallist.join("/")}`;
      let folders = new Set();
      var _filteredData = xval?.filter((item: any) => {
        // console.log('path', item.path, 'current path', currentPath);
        if (item.path === currentPath || item.path === undefined) {
          //item.path = undefined;
          item.fullPath = item.path + "/" + item.fileName;
          return item;
        } else {
          let currFolder = item.path.replace(
            new RegExp(`^${currentPath}\\s*`),
            ""
          );
          let _currTopLevelFolder = currFolder.split("/").filter(Boolean)[0];
          if (!folders.has(_currTopLevelFolder)) {
            folders.add(_currTopLevelFolder);
            // const pathArray = currFolder.split('/').filter(Boolean);
            // item.path=pathArray[0];
            // item.path = _currTopLevelFolder;
            item.fileType = "folder";
            // item.fileName=undefined;
            return item;
          }
        }
      });
      setFilteredGridData(_filteredData);
      setIsSearchDataEmpty(true);
      // setAllowTableRowPerPage(10);             // ToDo make global for pagesize
    }
  };

  const setOldFileNameEvent = async (e: any) => {
    setOpen(true);
    if (selectedRow?.length > 1) {
      setHideInput(true);
      setHideInputValMsg("You can change only one record name at a time");
    } else if (selectedRow?.length === 1) {
      if (selectedRow[0]?.fileType === "folder") {
        await setNewFileName(selectedRow[0]?.path);
        await setOldFileName(selectedRow[0]?.path);
        setHideInput(false);
      } else {
        let splitFileName = selectedRow[0]?.fileName.split(".");
        await setOldFileName(splitFileName[0]);
        await setNewFileName(splitFileName[0]);
        await setRenameFileExtension("." + splitFileName[1]);
        setHideInput(false);
      }
    } else if (selectedRow?.length === 0) {
      setHideInput(true);
      setHideInputValMsg("Please select a record");
    }
  };

  return (
    <div className="table-wrapper-custom">
      {!click && (
        <>
          <DataTable
            data={filteredGridData} // ?.length > 0 ? filteredGridData : xval}
            search={true}
            // data={tabledatas}
            // rows={100}
            scrollable={true}
            // totalItems={pagecount}
            searchPlaceholder="Search"
            globalFilterValue=""
            selection={selectedRow}
            selectionMode="multiple"
            // onSelectAll={handleselectall}
            showHeader={true}
            scrollHeight="45vh"
            virtualRowHeight={32}
            onSelectionChange={(e) => handleselection(e)}
            resizableColumns={true}
            onGlobalFilter={(e) => handleSearchInputTextBox(e)}
          >
            <DataTable.HeaderBar>
              <div>
                <Tooltip
                  element={
                    <Button
                      type="primary"
                      className={`item-newfolder ${
                        isNewFolderBtnDisabled || !isSearchDataEmpty
                          ? "disabled"
                          : ""
                      }`}
                      textTransform={false}
                      iconRoot="common"
                      icon="slidercontrols-plus"
                      content="New Folder"
                      size="small"
                      onClick={handleCreateNewFolder}
                      iconPosition="left"
                      disabled={isNewFolderBtnDisabled || !isSearchDataEmpty}
                    />
                  }
                  content="Create a new folder"
                />
              </div>

              <div className="datatable-menu-item">
                {showProgress ? (
                  <div className="upload-and-scan">
                    <p>Please don't refresh while upload is in-progress</p>
                  </div>
                ) : showDownloadProgress ? (
                  <div className="upload-and-scan">
                    <p>Please don't refresh while download is in-progress</p>
                  </div>
                ) : (
                  <div className=" refresh-button">
                    <Tooltip
                      element={
                        <i
                          className="h-icon global refresh refresh-user-list scuf-medium"
                          title=""
                          aria-label=""
                          onClick={() => {
                            refreshCurrentPageOfTable();
                            handleSearchInputTextBox("");
                          }}
                        ></i>
                      }
                      content="Refresh"
                      position="bottom left"
                      size="mini"
                    />
                  </div>
                )}
                <Tooltip
                  element={
                    <Button
                      type="primary"
                      disabled={
                        showDownloadProgress ||
                        disablefuncupload ||
                        showReset ||
                        showProgress ||
                        !isSearchDataEmpty
                      }
                      textTransform={false}
                      className={
                        showDownloadProgress ||
                        disablefuncupload ||
                        showReset ||
                        showProgress ||
                        !isSearchDataEmpty
                          ? "item-uploaddisable-datamngmnt"
                          : "item-upload-datamngmnt"
                      }
                      iconRoot="global"
                      icon="file-upload"
                      content="Upload"
                      iconPosition="left"
                      size="small"
                    >
                      Upload
                      <input
                        id="browse-file"
                        type="file"
                        title="uploadfile"
                        className={
                          showDownloadProgress ||
                          disablefuncupload ||
                          showReset ||
                          showProgress ||
                          !isSearchDataEmpty
                            ? "item-uploaddisable-datamngmnt"
                            : "item-upload-datamngmnt"
                        }
                        disabled={
                          showDownloadProgress ||
                          disablefuncupload ||
                          showReset ||
                          showProgress ||
                          !isSearchDataEmpty
                        }
                        onChange={eventBeforeUploadApi}
                      />
                    </Button>
                  }
                  content={`Upload files for all VM' S to Access`}
                  position="bottom left"
                  size="mini"
                />
                <Tooltip
                  element={
                    <Button
                      type="primary"
                      textTransform={false}
                      disabled={
                        disablefunc || showProgress || showDownloadProgress
                      }
                      className={
                        disablefunc || showProgress || showDownloadProgress
                          ? "button-disabled"
                          : "button-enabled"
                      }
                      // className='disabled-button-cursor'
                      iconRoot="global"
                      icon="file-download"
                      content="Download"
                      iconPosition="left"
                      size="small"
                      onClick={handletoastdownload}
                    />
                  }
                  content="Download the selected items to this PC"
                  position="bottom left"
                  size="mini"
                />
                <Tooltip
                  element={
                    <Button
                      type="primary"
                      style={{ marginLeft: "5px" }}
                      disabled={showDownloadProgress || disablefunc}
                      textTransform={false}
                      className={
                        showDownloadProgress || disablefunc
                          ? "button-disabled"
                          : "button-enabled"
                      }
                      iconRoot="common"
                      icon="delete"
                      content="Delete"
                      iconPosition="left"
                      size="small"
                      onClick={handletoastdelete}
                    />
                  }
                  content="Delete the selected items across VMs"
                  position="bottom right"
                  size="mini"
                />
              </div>
            </DataTable.HeaderBar>
            <DataTable.Column
              field="fileName"
              className="name cell-crsr-pntr"
              header="Name"
              sortable={true}
              initialWidth="130px"
              renderer={
                isSearchDataEmpty
                  ? rendererNameColNoSearch
                  : rendererNameColSearch
              }
            />

            {/* <DataTable.Column field='fileType' header='Type' sortable={true} initialWidth='80px' renderer={statusRendererfiletype}/> */}
            <DataTable.Column
              field="path"
              header="Path"
              sortable={true}
              initialWidth={isSearchDataEmpty ? "0px" : "120px"}
              renderer={rendererPath}
            />
            {/* <DataTable.Column field='path' header='Path' sortable={true} initialWidth='120px'/> */}

            <DataTable.Column
              field="fileSize"
              header="File Size"
              className="file"
              sortable={true}
              initialWidth="80px"
              renderer={rendererFileSizeCol}
            />
            <DataTable.Column
              field="source"
              header="Source"
              sortable={true}
              initialWidth="80px"
            />
            <DataTable.Column
              field="updatedAt"
              header="Last Modified On"
              sortable={true}
              initialWidth="100px"
            />

            <DataTable.Column
              field="updatedBy"
              header="Last Modified By"
              sortable={true}
              initialWidth="178px"
              renderer={statusRendererUpdatedBy}
            />
            <DataTable.Column
              field="status"
              header="Status"
              sortable={true}
              initialWidth="80px"
              renderer={(e) => statusRender(e)}
            />
            {/* <DataTable.Column
                        field='' header=''
                        renderer={(e) => statusRendererRowFileRename(e)}
                        initialWidth='50px' /> */}
          </DataTable>
          <div className="footer">
            {
              // (xval?.length > allowTableRowPerPage) && isSearchDataEmpty ?
              //     <DataTable.Pagination
              //         onPageChange={(event) => {
              //             handlepagination(event);
              //         }}
              //         expandEllipsis={true}
              //         totalItems={pagecount}
              //         itemsPerPage={allowTableRowPerPage}
              //         boundaryRange={3}
              //     />
              //     : ''
            }
            {
              <div className="progress-bar">
                <div className="progress-div">
                  {(showDownloadProgress || showProgress || !retryBtnState) && (
                    <div className="fileupload-progressbar">
                      {showDownloadProgress || showProgress ? (
                        <Progress
                          value={
                            showDownloadProgress ? progressDownload : progress
                          }
                          total={100}
                          size="xsmall"
                          showProgress={true}
                        ></Progress>
                      ) : null}
                      <span className="inprogresstext">
                        {showDownloadProgress
                          ? `File download in-progress`
                          : `${fileNameUpload} upload in-progress (${
                              progress % 1 === 0
                                ? progress
                                : progress?.toFixed(2)
                            } %)`}
                      </span>
                    </div>
                  )}
                </div>
                <div className="icon-div">
                  {!retryBtnState && (
                    <Icon
                      className="retry-icon"
                      root="global"
                      name="redo"
                      size="medium"
                      onClick={() => realDownload()}
                    />
                  )}
                  <span className="inprogresstext">
                    {showReset === true &&
                    showRetry === false &&
                    showProgress === false
                      ? `${fileNameUpload} upload failed. Please cancel to upload again.`
                      : null}
                  </span>
                  {showRetry && (
                    <Tooltip
                      content="Retry upload"
                      element={
                        <Icon
                          className="retry-icon"
                          root="global"
                          name="redo"
                          size="medium"
                          onClick={uploadFile}
                        />
                      }
                    />
                  )}
                  {showReset && (
                    <Icon
                      className="resetbtn"
                      root="global"
                      name="close"
                      size="medium"
                      onClick={resetFileUpload}
                    />
                  )}
                </div>
              </div>
            }
          </div>
          <ReactToastify.ToastContainer
            hideProgressBar={true}
            closeOnClick={true}
            closeButton={false}
            newestOnTop={true}
            position="top-left"
            toastClassName="toast-notification-wrap"
            theme="none"
            margin-left="100px"
          />
        </>
      )}
    </div>
  );
};
