// @ts-ignore
// import { environment } from '@honeywell/env/environment';

export const config = {
  BASE_URL: process.env.REACT_APP_FORGE_BASE_URL,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  LOGOUT_REDIRECT_URI: process.env.REACT_APP_LOGOUT_REDIRECT_URI,
  API_SERVICE: process.env.REACT_APP_API_SERVICE,
  INTERVAL: process.env.REACT_APP_INTERVAL_IN_HOURS ? Number(process.env.REACT_APP_INTERVAL_IN_HOURS) * 60 * 60 * 1000 : undefined,
};

export const REQUIRED_PERMISSIONS = {
  VIEW_CUSTOMER_PRIME_SPACE: ['dp.twin.common.dashboard.view'],
  TRANSFER_DATA_OR_FILES_IN_AND_OUT:['dp.twin.filetransfer.view', 'dp.twin.filetransfer.upload', 'dp.twin.filetransfer.download'],
  LINKING_OTHER_HONEYWELL_APPS_TO_PRIME_SPACE: ['dp.twin.linkotherapps'],
  PROVISION_NEW_PRIME_SPACE_OR_SITE:['dp.twin.site.manage'],
  MODIFY_OR_DELETE_DATA_FROM_SITE_ON_PRIME_SPACE:['dp.twin.filetransfer.view', 'dp.twin.filetransfer.upload', 'dp.twin.filetransfer.download', 'dp.twin.filetransfer.delete'],
  ACCESS_AND_WORK_ON_VM:['dp.twin.vm.access'],
  SELF_SERVICE_INDIVIDUAL_VMS:['dp.twin.vm.selfservice'],
  ADD_CUSTOMER_ADMIN: ['dp.twin.customeradmin.manage'],
  CAN_VIEW_USER_MANAGEMENT: ['dp.twin.user.management.view'],
  CAN_ADD_USER_MANAGEMENT: ['dp.twin.user.management.add'],
  CAN_UPDATE_USER_MANAGEMENT: ['dp.twin.user.management.update'],
  CAN_DELETE_USER_MANAGEMENT: ['dp.twin.user.management.delete']
}

export const ROLES_REQUIRED = {
  TWIN_HONEYWELL_IT: ['dp.twin.honeywell.it'], //dp.honeywell.it
  TWIN_ADMIN:['dp.twin.admin'],//dp.customer.admin,
  TWIN_ENGINEER:['dp.twin.engineer'], //dp.customer.engineer
  TWIN_HONEYWELL_COMMISSIONING_ENGINEER:['dp.twin.honeywell.commnengineer'],//dp.honeywell.commnenginee
  TWIN_HONEYWELL_MAINTENENCE_ENGINEER:['dp.twin.honeywell.maintengineer'], //dp.honeywell.maintengineer
}

export default config;
