import { TranslationConsumer } from "@scuf/localization";
import { Icon, Checkbox, Modal } from "@scuf/common";
import "./css/deviceDetail.scss";
import {
  getWMKSDetails,
  removeSelectedVM,
  setLoading,
  setSelectedVM,
  setSelectedVMWare,
} from "../../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppState } from "../../../../common/store/combinedReducer";
import _ from "lodash";
import GreenDot from "../../../../common/assets/DotsGreen.png";
import RedDot from "../../../../common/assets/StatusRed.png";
import { customToast } from "../../../../App";
import { REQUIRED_PERMISSIONS } from "../../../../common/authentication/config";

const DeviceDetails = (props: any) => {
  const dispatch = useDispatch();
  const powerOn = GreenDot;
  const powerOff = RedDot;
  const selectedVM = useSelector((state: AppState) => {
    return state.reducer.selectedVM;
  });
  const selectedVMWare = useSelector((state: AppState) => {
    return state.reducer.selectedVMWare;
  });
  const wmksDetails = useSelector((state: AppState) => {
    return state.reducer.wmksDetails;
  });
  const wmksStatus = useSelector((state: AppState) => {
    return state.reducer.wmksStatus;
  });
  const [clicked, setClicked] = useState(false);
  const [selectedVMData, setSelectedVMData] = useState<any>([]);
  const vmDetails = props?.vmDetails;
  const [dom, setDom] = useState(false);
  const [selectedId, setId] = useState("");

  useEffect(() => {
    if (props.clearAll === true) {
      vmDetails?.forEach((element: any) => {
        element.checked = false;
      });
      setSelectedVMData([]);
      props.setClearAll(false);
    }
    if (selectedVM?.length > 0) {
      if (_.some(vmDetails, ["checked", true]) === true) {
        // console.log('selectedVMData', selectedVMData, _.every(selectedVMData, ['snapshot', true]))
        if (_.every(selectedVMData, ["snapshot", true]) === true) {
          props.setRestoreSnapshot(false);
        } else if (_.every(selectedVMData, ["snapshot", true]) === false) {
          props.setRestoreSnapshot(true);
        }
        if (_.every(selectedVMData, ["status", "POWERED_ON"]) === true) {
          props.setRestart(false);
          // props.setShutDown(false);
          props.setCreateSnapshotDisable(false);
        } else if (
          _.every(selectedVMData, ["status", "POWERED_OFF"]) === true
        ) {
          props.setRestart(true);
          // props.setShutDown(true);
          props.setCreateSnapshotDisable(false);
        } else if (
          _.every(selectedVMData, ["status", "POWERED_ON"]) === false
        ) {
          props.setRestart(true);
          // props.setShutDown(true);
          props.setCreateSnapshotDisable(false);
        }

        if (_.some(selectedVMData, ["isShutdownEnabled", false])) {
          props.setShutDown(true); // shutdown is disabled for linux vm
        } else if (_.every(selectedVMData, ["isShutdownEnabled", true])) {
          if (_.every(selectedVMData, ["status", "POWERED_ON"])) {
            props.setShutDown(false);
          } else {
            props.setShutDown(true);
          }
        }
      }
    } else {
      props.setRestoreSnapshot(true);
      props.setRestart(true);
      props.setShutDown(true);
      props.setCreateSnapshotDisable(true);
    }
  }, [vmDetails, selectedVMData, props.clearAll]);

  function getVM(id: string, name: string, status: string) {
    const checkPermission = REQUIRED_PERMISSIONS.ACCESS_AND_WORK_ON_VM.every(
      (p: string) => props.orgData?.permissions?.includes(p)
    );
    if (checkPermission && props.orgData?.name && status === "POWERED_ON") {
      setId(id);
      dispatch(setSelectedVMWare({ name, id }));
      dispatch(
        getWMKSDetails({
          id,
          accessToken: localStorage.getItem("access_token"),
          siteId: props.orgData?.siteId,
        })
      );
      setClicked(true);
    }
  }
  const formatDate = (date: any) => {
    const options: any = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const formatted = new Date(date).toLocaleDateString("en-US", options);
    const dateParts = formatted
      .substring(0, formatted.indexOf(","))
      .split(" ")
      .reverse()
      .join(" ");
    // Return the formatted date with uppercase month abbreviation and desired format
    const formattedDate =
      dateParts + formatted.substr(formatted.indexOf(",") + 1);
    return formattedDate;
  };
  useEffect(() => {
    if (
      wmksDetails?.host !== null &&
      wmksDetails?.host !== undefined &&
      clicked &&
      wmksStatus === 200
    ) {
      window
        .open(
          `/dashboard/vmware` +
            `/${props.orgData?.siteId}/${selectedVMWare.name}`,
          "",
          "noopener"
        )
        ?.focus();
      localStorage.setItem(
        selectedVMWare.name,
        JSON.stringify({
          name: selectedVMWare.name,
          id: selectedId,
          siteId: props.orgData?.siteId,
          wmksData: wmksDetails,
        })
      );
      setClicked(false);
    } else if (clicked) {
      if (
        wmksStatus === 409 &&
        (wmksDetails && wmksDetails.minorErrorCode) === "VIM_INVALID_STATE"
      )
        customToast("VM in use. Please try after sometime", "critical");
      else if (
        wmksStatus === 409 &&
        wmksDetails &&
        wmksDetails.minorErrorCode === "INVALID_STATE"
      )
        customToast(
          `You cannot view the console since VM ${selectedVMWare.name} is not powered on. To view the console first refresh and power on the virtual machine`,
          "critical"
        );
      else if (wmksStatus === 500)
        customToast(
          "Error in connection. Please try after sometime",
          "critical"
        );
      else if (wmksStatus === 401 || wmksStatus === 403)
        customToast("Unauthorized to access the vm.", "critical");
    }
  }, [wmksDetails, wmksStatus]);

  const validateCheckBox = (checkData: any) => {
    let checked: boolean = false;
    vmDetails?.forEach((element: any) => {
      if (element.id === checkData.id) {
        checked =
          element.checked !== undefined
            ? element.checked
            : checkData.checked !== undefined
            ? checkData.checked
            : false;
      }
    });
    return checked;
  };
  const handleCheckbox = (checked: boolean, data: any) => {
    vmDetails?.forEach((vm: any) => {
      if (vm.id === data.id) {
        if (checked) {
          vm.checked = true;
          data.checked = true;
          selectedVMData.push(vm);
          const val = _.uniq(selectedVMData);
          setSelectedVMData(val);
          dispatch(setSelectedVM(vm));
        } else if (!checked) {
          vm.checked = false;
          data.checked = false;
          const newSelected: any = selectedVMData?.filter(
            (cur: any) => cur.id !== data.id
          );
          setSelectedVMData(newSelected);
          dispatch(removeSelectedVM(vm));
        }
      }
    });
    setDom(!dom);
  };

  const sortDataByKey = (data: any) => {
    let sortedData;
    if (!data) {
      return [];
    }
    sortedData = data.sort((a: any, b: any) => {
      const x = a.key ? a.key.toLowerCase() : "";
      const y = b.key ? b.key.toLowerCase() : "";
      if (x > y) {
        return 1;
      }
      if (x < y) {
        return -1;
      }
      return 0;
    });
    return sortedData;
  };

  const handlePower = (checked: boolean, data: any) => {
    console.log("vm", data, checked);
    if (props.orgData && props.orgData.IsVCDAlive === false) {
      customToast(
        "This site is not available currently, please try after sometime.",
        "critical"
      );
      props.handleClearAll();
    } else {
      vmDetails?.forEach((vm: any) => {
        console.log("vm", vm, data, checked);
        if (vm.id === data.id) {
          if (checked) {
            vm.isOn = true;
            data.isOn = true;
            vm.isIntermediate = true;
            data.isIntermediate = true;
            console.log("vm power on", vm);
            props.setCloseConfirmModal(true);
            props.setHeader("Power On");
            props.setContent("power on");
            props.setPoweredVm(data.id);
          } else if (!checked) {
            vm.isOn = false;
            data.isOn = false;
            vm.isIntermediate = true;
            data.isIntermediate = true;
            console.log("vm power off", vm);
            props.setCloseConfirmModal(true);
            props.setHeader("Power Off");
            props.setContent("power off");
            props.setPoweredVm(data.id);
          }
        }
      });
    }
    setDom(!dom);
  };

  return (
    <div className="detail-wrapper">
      {vmDetails?.map((data: any) => {
        return (
          <div className="plant-detail-wrapper">
            <div className="card-header">
              <div className="org-container">
                <Icon root="global" name={"pc"} size="medium" color="white" />
                <label
                  className={
                    REQUIRED_PERMISSIONS.ACCESS_AND_WORK_ON_VM.every(
                      (p: string) => props.orgData?.permissions?.includes(p)
                    ) && data.status === "POWERED_ON"
                      ? "org-icon-label"
                      : "org-icon-label-noPermission"
                  }
                  onClick={() => getVM(data.id, data.name, data.status)}
                >
                  {data.name}
                </label>
              </div>
              {REQUIRED_PERMISSIONS.SELF_SERVICE_INDIVIDUAL_VMS.every(
                (p: string) => props.orgData?.permissions?.includes(p)
              ) &&
              (data.status === "POWERED_ON" ||
                data.status === "POWERED_OFF") ? (
                <Checkbox
                  className="checkbox-dashboard"
                  checked={validateCheckBox(data)}
                  onChange={(checked) => handleCheckbox(checked, data)}
                  id={data.id}
                  // disabled={!data.isSelfServiceEnabled}
                />
              ) : null}
            </div>
            <div className="card-container">
              <div className="vm-card-status">
                <div className="span-container" id="node-state">
                  <span className="card-data-value">
                    Node State &nbsp;
                    {REQUIRED_PERMISSIONS.SELF_SERVICE_INDIVIDUAL_VMS.every(
                      (p: string) => props.orgData?.permissions?.includes(p)
                    ) &&
                    (data.status === "POWERED_ON" ||
                      data.status === "POWERED_OFF") ? (
                      <Checkbox
                        className={
                          data.isIntermediate
                            ? "intermediate powerToggle"
                            : "powerToggle"
                        }
                        toggle={true}
                        id={"powerToggle" + data.id}
                        checked={data.isOn}
                        indeterminate={data.isIntermediate}
                        disabled={data.isIntermediate}
                        onChange={(checked: boolean) =>
                          handlePower(checked, data)
                        }
                      />
                    ) : null}
                    <span className="card-data-val">{data.status}</span>
                  </span>
                </div>
              </div>
              <div className="card-device-version">
                {data &&
                  data.vmMetadata &&
                  sortDataByKey(data.vmMetadata).map((details: any) => {
                    if (
                      details.key === "Version" ||
                      details.key === "Server Patch" ||
                      details.key === "Node Type"
                    ) {
                      return (
                        <span className="card-data-value">
                          {details.key}:{" "}
                          <span className="card-data-val version">
                            {details.value}
                          </span>
                        </span>
                      );
                    }
                  })}
                <span className="card-data-value card-date">
                  Last Snapshot :{" "}
                  <span className="card-data-val">
                    {data.snapshotCreated
                      ? formatDate(data.snapshotCreated)
                      : "-- --"}
                  </span>
                </span>
                <span className="card-data-value card-date">
                  Last Restored :{" "}
                  <span className="card-data-val">
                    {data.snapshotRestored
                      ? formatDate(data.snapshotRestored)
                      : "-- --"}
                  </span>
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default DeviceDetails;
