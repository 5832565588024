import { useEffect, useState } from "react";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../../../common/authentication/authService";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  getCSRFToken,
  getDetails,
  getPermissions,
  setActivePage,
  setActiveSignalR,
  setHubConnection,
  setLoading,
  setLoggedIn,
  setSelectedView,
} from "../../store/action";
import { AppState } from "../../../common/store/combinedReducer";
import { Loader } from "@scuf/common";
import _ from "lodash";

export default function RedirectPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let sub: any;
  // const [token, setToken] = useState('');
  const orgDataStatus = useSelector((state: AppState) => {
    return state.reducer.orgDataStatus;
  });
  const plantName = useSelector((state: AppState) => {
    return state.reducer.plantName;
  });
  const loading = useSelector((state: AppState) => {
    return state.reducer.loading;
  });
  const csrf = useSelector((state: AppState) => {
    return state.reducer.csrf;
  });
  const csrfStatus = useSelector((state: AppState) => {
    return state.reducer.csrfStatus;
  });
  const permissions = useSelector((state: AppState) => {
    return state.reducer.permissions;
  });

  const permissionStatus = useSelector((state: AppState) => {
    return state.reducer.permissionStatus;
  });

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      localStorage.getItem("access_token") !== undefined &&
      permissionStatus !== 500
    ) {
      const orgVal: any = [];
      permissions?.forEach((per: any) => {
        if (per.resources) {
          orgVal.push(per.resources);
        }
      });
      if (orgDataStatus === 0) {
        const index: any = localStorage.getItem("index") || 0;
        fetchDetails(orgVal[index]);
      } else if (orgDataStatus !== 0) {
        dispatch(setLoggedIn(true));
        localStorage.setItem("logged_user", JSON.stringify(true));
        navigate("/dashboard");
      }
    } else if (
      permissionStatus !== 0 &&
      (permissions === null ||
        (permissions?.length <= 0 &&
          localStorage.getItem("access_token") !== undefined) ||
        permissionStatus === 500)
    ) {
      dispatch(setLoggedIn(false));
      navigate("/loginFailed");
    }
  }, [permissions, orgDataStatus]);

  useEffect(() => {
    sub = AuthProvider().getUserId();
    if (csrfStatus === 200 && sub) {
      // console.log('csrfStatus', csrfStatus, sub, csrf);
      if (sub && csrf === true) {
        dispatch(
          getPermissions({
            userSub: sub,
            authorization: localStorage.getItem("access_token"),
          })
        );
      } else if (csrf === false) {
        setTimeout(() => {
          navigate("/loginFailed");
        }, 500);
      }
    } else if (csrfStatus !== 200 && csrfStatus !== 0) {
      setTimeout(() => {
        navigate("/loginFailed");
      }, 500);
    }
  }, [csrfStatus]);

  const fetchDetails = (orgs: any) => {
    dispatch(
      getDetails({ authorization: localStorage.getItem("access_token"), orgs })
    );
    localStorage.setItem("logged_user", JSON.stringify(true));
    dispatch(setLoggedIn(true));
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const parsedUrl: any = queryString.parseUrl(window.location.href);
        if (
          parsedUrl &&
          parsedUrl.hasOwnProperty("query") &&
          parsedUrl.query.code
        ) {
          if (
            localStorage.getItem("token-generated") === undefined ||
            localStorage.getItem("token-generated") === null
          ) {
            const tokenData: any = await AuthProvider().acquireAccessToken(
              parsedUrl.query.code
            );
            if (tokenData && tokenData.access_token) {
              dispatch(getCSRFToken({ authorization: tokenData.access_token }));
              dispatch(setLoading(true));
            } else {
              setTimeout(() => {
                navigate("/loginFailed");
              }, 500);
            }
          } else if (AuthProvider().isAuthenticated()) {
            dispatch(
              getCSRFToken({
                authorization: localStorage.getItem("access_token"),
              })
            );
            dispatch(setActivePage(0));
            dispatch(setLoading(true));
          } else if (
            !AuthProvider().isAuthenticated() &&
            localStorage.getItem("access_token") !== undefined
          ) {
            const tokenData: any = await AuthProvider().acquireAccessToken(
              parsedUrl.query.code
            );
            if (tokenData && tokenData.access_token) {
              dispatch(getCSRFToken({ authorization: tokenData.access_token }));
              dispatch(setLoading(true));
            }
          } else {
            setTimeout(() => {
              navigate("/logout");
            }, 500);
          }
        } else {
          setTimeout(() => {
            navigate("/logout");
          }, 500);
        }
      } catch (err) {}
    }
    fetchData();
  }, []);
  // loading
  return (
    <>
      {loading ? (
        <Loader>
          <div className="placeholder" />
        </Loader>
      ) : null}
    </>
  );
}
