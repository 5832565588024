import { IExpandDetails } from './model';

export const SET_DETAILS = 'SET_DETAILS';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const SET_SELECTED_VMWARE = 'SET_SELECTED_VMWARE';
export const SET_AUTH_URL = 'SET_AUTH_URL';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const GET_DATA = 'GET_DATA';
export const SET_DATA = 'SET_DATA';
export const GET_WMKS_DATA = 'GET_WMKS_DATA';
export const SET_WMKS_DATA = 'SET_WMKS_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LOADING = 'SET_LOADING';
export const GET_PERMISSION = 'GET_PERMISSION';
export const SET_PERMISSION = 'SET_PERMISSION';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_DROP_DOWN_VALUE = 'SET_DROP_DOWN_VALUE';
export const GET_ORG_STATUS = 'GET_ORG_STATUS';
export const SET_SELECTED_VM = 'SET_SELECTED_VM';
export const REMOVE_SELECTED_VM = 'REMOVE_SELECTED_VM';
export const GET_SHUTDOWN = 'GET_SHUTDOWN';
export const SET_SHUTDOWN = 'SET_SHUTDOWN';
export const GET_REBOOT = 'GET_REBOOT';
export const SET_REBOOT = 'SET_REBOOT';
export const SET_SHUTDOWN_STATUS = 'SET_SHUTDOWN_STATUS';
export const SET_REBOOT_STATUS = 'SET_REBOOT_STATUS';
export const GET_CREATE_SNAP_SHOT = 'GET_CREATE_SNAP_SHOT';
export const SET_CREATE_SNAP_SHOT = 'SET_CREATE_SNAP_SHOT';
export const GET_RESTORE_SNAP_SHOT = 'GET_RESTORE_SNAP_SHOT';
export const SET_RESTORE_SNAP_SHOT = 'SET_RESTORE_SNAP_SHOT';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const GET_ASSIGNED_ROLES = 'GET_ASSIGNED_ROLES';
export const SET_ASSIGNED_ROLES = 'SET_ASSIGNED_ROLES';
export const GET_USER_DELETE = 'GET_USER_DELETE';
export const SET_USER_DELETE = 'SET_USER_DELETE';
export const SET_USER_ROLES_STATUS = 'SET_USER_ROLES_STATUS';
export const SET_ASSIGNED_STATUS = 'SET_ASSIGNED_STATUS';
export const SET_NEW_USER_DATA = 'SET_NEW_USER_DATA';
export const SET_NEW_USER_ROLE = 'SET_NEW_USER_ROLE';
export const GET_UPDATE_USER_ROLES = 'GET_UPDATE_USER_ROLES';
export const SET_UPDATE_USER_ROLES = 'SET_UPDATE_USER_ROLES';
export const SET_LOADING_USER_ROLES = 'SET_LOADING_USER_ROLES';
export const GET_START = 'GET_START';
export const SET_START = 'SET_START';
//datamanagement 
export const GET_TABLE_DATA = 'GET_TABLE_DATA';
//New
export const DOWLOAD_DASHBOARD_SELECT_ROWS_DATA = 'DOWLOAD_DASHBOARD_SELECT_ROWS_DATA';
export const SET_DOWLOAD_DASHBOARD_SELECT_ROWS_DATA = 'SET_DOWLOAD_DASHBOARD_SELECT_ROWS_DATA';
export const BLOB_SIZE_DASHBOARD_GET_DATA = 'BLOB_SIZE_DASHBOARD_GET_DATA';
export const BLOB_SIZE_DASHBOARD_SET_DATA = 'BLOB_SIZE_DASHBOARD_SET_DATA';
export const DELETE_TABLE_DATA = 'DELETE_TABLE_DATA';
export const SET_DELETE_TABLE_DATA = 'SET_DELETE_TABLE_DATA';
export const RENAME_FILE_DATA = 'GET_RENAME_FILE_DATA';
export const SET_RENAME_FILE_DATA = 'SET_RENAME_FILE_DATA';
export const GET_RENAME_FOLDER_DATA = 'GET_RENAME_FOLDER_DATA';
export const SET_RENAME_FOLDER_DATA = 'SET_RENAME_FOLDER_DATA';
export const GET_DASHBOARD_UPLOAD_VALIDATION = 'GET_DASHBOARD_UPLOAD_VALIDATION';
export const SET_DASHBOARD_UPLOAD_VALIDATION = 'SET_DASHBOARD_UPLOAD_VALIDATION';
export const SET_POWER_ON = 'SET_POWER_ON';
export const GET_POWER_ON = 'GET_POWER_ON';
export const SET_POWER_OFF = 'SET_POWER_OFF';
export const GET_POWER_OFF = 'GET_POWER_OFF';
export const GET_RESET = 'GET_RESET';
export const SET_RESET = 'SET_RESET';
export const SET_VMID_NOTIFICATION = 'SET_VMID_NOTIFICATION'


export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const GET_UPLOAD_FILE = 'GET_UPLOAD_FILE';
export const SET_UPLOAD_FILE = 'SET_UPLOAD_FILE';

//chunk based file upload
export const GET_UPLOAD_CHUNK = 'GET_UPLOAD_CHUNK';
export const SET_UPLOAD_CHUNK =  'SET_UPLOAD_CHUNK';

export const SET_SITEID = 'SET_SITEID';
// signalr
export const SET_HUB_CONNECTION = 'SET_HUB_CONNECTION';
export const INITIATE_SIGNALR = 'INITIATE_SIGNALR';
export const GET_TOKEN_CSRF= 'GET_TOKEN_CSRF';
export const SET_TOKEN_CSRF= 'SET_TOKEN_CSRF';
export const SIGNALR_CONNECTION = 'SIGNALR_CONNECTION';

export const getResetStatus = (payload: any) => ({
    type: GET_RESET,
    payload
});
export const setResetStatus = (payload: any) => ({
    type: SET_RESET,
    payload
});
export const getPowerOff = (payload: any) => ({
    type: GET_POWER_OFF,
    payload
});
export const setPowerOff = (payload: any) => ({
    type: SET_POWER_OFF,
    payload
});
export const getPowerOn = (payload: any) => ({
    type: GET_POWER_ON,
    payload
});
export const setPowerOn = (payload: any) => ({
    type: SET_POWER_ON,
    payload
});
export const setSignalR = (payload: any) => ({
    type: SIGNALR_CONNECTION,
    payload
});
export const setSiteID = (payload: any) => ({
    type: SET_SITEID,
    payload
});
export const setUserRoleStatus = (payload: number) => ({
    type: SET_USER_ROLES_STATUS,
    payload
});
export const setAssignedStatus = (payload: number) => ({
    type: SET_ASSIGNED_STATUS,
    payload
});
export const setLoading = (payload: boolean) => ({
    type: SET_LOADING,
    payload
});
export const setVmIdNotification = (payload: any) => ({
    type: SET_VMID_NOTIFICATION,
    payload
});
export const getOrgStatus = (payload: any) => ({
    type: GET_ORG_STATUS,
    payload
});
export const getWMKSDetails = (payload: any) => ({
    type: GET_WMKS_DATA,
    payload
});
export const setWMKSData = (payload: any) => ({
    type: SET_WMKS_DATA,
    payload
});
export const setSelectedView = (payload: IExpandDetails) => ({
    type: SET_SELECTED_VIEW,
    payload
});
export const setActivePage = (payload: any) => ({
    type: SET_ACTIVE_PAGE,
    payload
});
export const setUserData = (payload: any) => ({
    type: SET_USER_DATA,
    payload
});
export const getDetails = (payload: any) => ({
    type: GET_DATA,
    payload
});
export const setSelectedVMWare = (payload: any) => ({
    type: SET_SELECTED_VMWARE,
    payload
});
export const setVMData = (payload: any) => ({
    type: SET_DATA,
    payload
});

export const setPermissions = (payload: any) => ({
    type: SET_PERMISSION,
    payload
});

export const getPermissions = (payload: any) => ({
    type: GET_PERMISSION,
    payload
});
export const setLoggedIn = (payload: any) => ({
    type: SET_LOGGED_IN,
    payload
});
export const setSelectedPlant = (payload: any) => ({
    type: SET_DROP_DOWN_VALUE,
    payload
});
export const setSelectedVM = (payload: any) => ({
    type: SET_SELECTED_VM,
    payload
});
export const removeSelectedVM = (payload?: any) => ({
    type: REMOVE_SELECTED_VM,
    payload
});
export const getShutDownData = (payload: any) => ({
    type: GET_SHUTDOWN,
    payload
});
export const setShutDownData = (payload: any) => ({
    type: SET_SHUTDOWN,
    payload
});
export const setShutDownStatus = (payload: any) => ({
    type: SET_SHUTDOWN_STATUS,
    payload
});

export const getStartData = (payload: any) => ({
    type: GET_START,
    payload
});
export const setStartData = (payload: any) => ({
    type: SET_START,
    payload
});
export const getRebootData = (payload: any) => ({
    type: GET_REBOOT,
    payload
});
export const setRebootData = (payload: any) => ({
    type: SET_REBOOT,
    payload
});
export const setRebootStatus = (payload: any) => ({
    type: SET_REBOOT_STATUS,
    payload
});
export const getCreateSnapshot = (payload: any) => ({
    type: GET_CREATE_SNAP_SHOT,
    payload
});
export const setCreateSnapshot = (payload: any) => ({
    type: SET_CREATE_SNAP_SHOT,
    payload
});
export const getRestoreSnapshot = (payload: any) => ({
    type: GET_RESTORE_SNAP_SHOT,
    payload
});
export const setRestoreSnapshot = (payload: any) => ({
    type: SET_RESTORE_SNAP_SHOT,
    payload
});
export const getUserRoles = (payload: any) => ({
    type: GET_USER_ROLES,
    payload
});
export const setUserRoles = (payload: any) => ({
    type: SET_USER_ROLES,
    payload
});
export const getAssignedRoles = (payload: any) => ({
    type: GET_ASSIGNED_ROLES,
    payload
});
export const setAssignedRoles = (payload: any) => ({
    type: SET_ASSIGNED_ROLES,
    payload
});
export const getDeleteUser = (payload: any) => ({
    type: GET_USER_DELETE,
    payload
});
export const setDeleteUser = (payload: any) => ({
    type: SET_USER_DELETE,
    payload
});
// export const removeNewUserSearch = (payload: any) => ({
//     type: REMOVE_NEW_USER_SEARCH,
//     payload
// });
export const setNewUserData = (payload: any) => ({
    type: SET_NEW_USER_DATA,
    payload
});
export const addNewUserRoles = (payload: any) => ({
    type: SET_NEW_USER_ROLE,
    payload
});
// updating userRoles
export const getUpdateUserRoles = (payload: any) => ({
    type: GET_UPDATE_USER_ROLES,
    payload
});
export const setUpdateUserRoles = (payload: any) => ({
    type: SET_UPDATE_USER_ROLES,
    payload
});
export const setLoadingUserRoles = (payload: any) => ({
    type: SET_LOADING_USER_ROLES,
    payload
});
export const setActiveSignalR = (payload: boolean) => ({
    type: INITIATE_SIGNALR,
    payload,
});
export const setHubConnection = (payload: any) => ({
    type: SET_HUB_CONNECTION,
    payload,
});
//Datamanagement
export const gettabledata = (payload: any) => ({
    type: GET_TABLE_DATA,
    payload
});
export const settabledata = (payload: any) => ({
    type: SET_TABLE_DATA,
    payload
});
export const getuploaddata = (payload: any) => ({
    type: GET_UPLOAD_FILE,
    payload: payload
})
export const setuploaddata = (payload: any) => ({
    type: SET_UPLOAD_FILE,
    payload
})


//New Delete
export const deleteDataManagementRecord = (payload: any) => ({
    type: DELETE_TABLE_DATA,
    payload
})
export const setDeleteDataManagementRecord = (payload: any) => ({
    type: SET_DELETE_TABLE_DATA,
    payload: payload
})

export const downloadRecordFile = (payload: any) => ({
    type: DOWLOAD_DASHBOARD_SELECT_ROWS_DATA,
    payload
})
export const setdownloadRecordFile = (payload: any) => ({
    type: SET_DOWLOAD_DASHBOARD_SELECT_ROWS_DATA,
    payload
})
export const blobSizeDashboard = (payload: any) => ({
    type: BLOB_SIZE_DASHBOARD_GET_DATA,
    payload
})
export const setblobSizedata = (payload: any) => ({
    type: BLOB_SIZE_DASHBOARD_SET_DATA,
    payload
})
export const renameFileApiDashboard = (payload: any) => (
    {
        type: RENAME_FILE_DATA,
        payload
    }
)
export const setRenameFileApiDashboard = (payload: any) => (
    {
        type: SET_RENAME_FILE_DATA,
        payload
    }
)
export const renameFolderApiDashboard = (payload: any) => (
    {
        type: GET_RENAME_FOLDER_DATA,
        payload
    }
)
export const setRenameFolderApiDashboard = (payload: any) => (
    {
        type: SET_RENAME_FOLDER_DATA,
        payload
    }
)
export const getDashboardUploadValidationApi = (payload: any) => (
    {
        type: GET_DASHBOARD_UPLOAD_VALIDATION,
        payload
    }
)
export const setDashboardUploadValidationApi = (payload: any) => (
    {
        type: SET_DASHBOARD_UPLOAD_VALIDATION,
        payload
    }
)

export const getUploadChunkApi = (payload: any) => (
    {
        type: GET_UPLOAD_CHUNK,
        payload
    }
)

export const setUploadChunkApi = (payload: any) => (
    {
        type: SET_UPLOAD_CHUNK,
        payload
    }
)


export const getCSRFToken = (payload: any) => ({
    type: GET_TOKEN_CSRF,
    payload
});

export const setCSRFToken = (payload: any) => ({
    type: SET_TOKEN_CSRF,
    payload
});
